import sizeof from "object-sizeof";
import DrawSegment from "../domain/entities/DrawSegment";
import { last } from "../domain/utils/entity_utils";


export const mapCanvasDataToDTO = (canvasLineSegmentsData: DrawSegment[]): DrawSegment[] => {
    const combined = [];
    canvasLineSegmentsData.forEach((drawSegment) => {
        const { seg, sId } = drawSegment;
        const lastSegment = last(combined) as DrawSegment;
        // If lastSegmentId is same as the segmentId, push in the same drawSegment
        if (lastSegment && lastSegment.sId === sId) {
            lastSegment.seg.push(...seg);
        } else {
            combined.push(drawSegment);
        }
    });

    // console.log("size-Canvas(kB):", sizeof(canvasLineSegmentsData)/1000, "; size-DTO (kB):", sizeof(dtoMap)/1000);
    return combined;
}