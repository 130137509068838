import { useEffect } from "react";
import { getAllUsers, getGifGameData, setGifIdMapData, setGifToDrawData, useAppDispatch, useAppSelector } from "../../store/store";
import Game from "../../../../../domain/entities/Game";
import { ClientPlayerData, GifFrame, GifFrameType, GifGameData, GifRound, GifTurn, idMapType } from "../../../../../common/types";
import Turn from "../../../../../domain/entities/Turn";
import { MessageType, TurnStatus } from "../../../../../common/Constants";
import _ from "lodash";
import { GIF_GUESSERS_POSITIONS, GIF_MESSAGE_POSITIONS } from "../../common/constants";
import DrawSegment from "../../../../../domain/entities/DrawSegment";
import Chat from "../../../../../domain/entities/Chat";

function GifDataPopulator() {
  const dispatch = useAppDispatch();
  const gifCompleteGameData = useAppSelector(getGifGameData);
  const playerData = useAppSelector(getAllUsers);

  useEffect(() => {
    // console.log(gifCompleteGameData);
    if (!gifCompleteGameData || !Object.keys(gifCompleteGameData))
      return;
    dispatch(setGifIdMapData(createPlayerIdToAvatarInfoMap(playerData)));
    dispatch(setGifToDrawData(gameToGifToDrawDataConverter(gifCompleteGameData)));
  }, [gifCompleteGameData]);
  return <div></div>;
}

function createPlayerIdToAvatarInfoMap(playerData: ClientPlayerData[]) {
  const idToNameAndAvatarMap: idMapType = {};
  playerData.forEach(player => {
    idToNameAndAvatarMap[player.uid] = { avatarId: player.avatarId.toString(), name: player.userName };
  });
  return idToNameAndAvatarMap;
}

function gameToGifToDrawDataConverter(game: Game): GifGameData | null {
  const gifRounds: GifRound[] = game.rounds.map((round, rIdx) => {
    // 1. Filter messages:
    const filteredMessages = game.chats.filter(ch =>
      ch.type === MessageType.CHAT_AFTER_GUESSING || ch.type === MessageType.NORMAL_CHAT);

    // 2. Timestamp Ordered messages:
    const orderedMessages = _.orderBy(filteredMessages, ['createdAt'], ['asc']);

    return {
      round: rIdx + 1,
      turnsData: round.turns.map((turn, tIdx) => {
        return {
          turn: tIdx + 1,
          word: turn.word,
          drawerId: turn.drawerId,
          frames: getFramesFromTurnData(turn, orderedMessages),
        };
      }) as GifTurn[]
    };
  });

  return gifRounds;
}

function getFramesFromTurnData(turn: Turn, orderedMessages: Chat[]): GifFrame[] {
  // console.log(turn);

  const startDrawingTs = turn.timestamps[TurnStatus.STARTED] / 1000;
  const endDrawingTs = turn.timestamps[TurnStatus.COMPLETED] / 1000;

  const turnDrawingMessages = orderedMessages.filter(msg => msg.createdAt >= startDrawingTs && msg.createdAt <= endDrawingTs);

  // 3. Chunk messages (since only fixed number of messages an be shown on screen):
  const chunkedMessages = _.chunk(turnDrawingMessages, GIF_MESSAGE_POSITIONS.length);

  // 4. Capture timestamps of the last message in each chunk:
  const endTimestampsOfMessagesInChunks = chunkedMessages.map(chunk => (chunk.length > 0 ? chunk[chunk.length - 1].createdAt : null));

  // 5. Preparing DrawingData Chunks Array:
  // 5.1 Ordering Drawing Data:
  const sortedDrawingData = _.sortBy(turn.drawingData, ['uAt']);
  // 5.2 Chunking Drawing Data as per timestamps
  const drawSegmentsChunkedArrays: DrawSegment[][] = [];
  let prevTimestamp = 0;

  endTimestampsOfMessagesInChunks.forEach((timestamp, i) => {
    if (!timestamp)
      return;
    const chunk = sortedDrawingData.filter(segment => (segment.uAt || 0) / 1000 >= prevTimestamp && (segment.uAt || 0) / 1000 < timestamp);
    drawSegmentsChunkedArrays.push(chunk);
    prevTimestamp = timestamp;
  });

  // 5.3 Add remaining drawSegments after the last timestamp
  const lastDrawChunk = sortedDrawingData.filter(segment => (segment.uAt || 0) / 1000 >= prevTimestamp);
  if (lastDrawChunk.length > 0) {
    drawSegmentsChunkedArrays.push(lastDrawChunk);
  }

  // 6. Combining Messages and DrawData Chunks and form 1 frame from both.
  const gifFrames: GifFrame[] = [];
  for (let i = 0; i < Math.max(drawSegmentsChunkedArrays.length, chunkedMessages.length); i++) {
    const gifFrame: GifFrame = {
      type: GifFrameType.NORMAL,
    };

    gifFrame.drawSegments = i <= drawSegmentsChunkedArrays.length - 1 ? drawSegmentsChunkedArrays[i] : [];
    gifFrame.messages = i <= chunkedMessages.length - 1 ? chunkedMessages[i]?.map(({ messageTransformed, playerId }) => ({
      message: messageTransformed,
      senderId: playerId
    })) : [];
    // 👉 todo: Currently word completely masked until last frame.
    // Might want to progressively reveal word.
    gifFrame.wordSoFar = i === drawSegmentsChunkedArrays.length - 1 ? turn.word : "_".repeat(turn.word.length);

    gifFrames.push(gifFrame);
  }

  const chunkedGuessers = _.chunk(Object.keys(turn.scores).filter(id => id !== turn.drawerId), GIF_GUESSERS_POSITIONS.length);
  chunkedGuessers.forEach(guessers => {
    const guessSummaryFrame: GifFrame = {
      type: GifFrameType.SUMMARY_OF_GUESSERS,
      messages: guessers.map((id) => ({ senderId: id, message: "" })),
      wordSoFar: turn.word,
    }
    gifFrames.push(guessSummaryFrame);
  });
  // console.log({gifFrames,chunkedMessages, drawSegmentsChunkedArrays, sortedDrawingData, endTimestampsOfMessagesInChunks, turnDrawingMessages, orderedMessages});
  return gifFrames;
}

export default GifDataPopulator;
