import ChatHistory from './ChatHistory'
import ChatInput from './ChatInput'

function ChatSection() {
  return (
    <div className="flex flex-col h-full">
      <ChatHistory />
      <ChatInput />
    </div>
  );
}

export default ChatSection