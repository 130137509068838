import React, { useState, useRef, MouseEvent, useEffect } from "react";
import { ColorResult, CompactPicker } from "react-color";
import {
  getUserCanvasPrefs,
  setUserCanvasPrefs,
  useAppDispatch,
  useAppSelector,
} from "../../store/store";
import { hexToHsl, hexToRgb } from "../../common/utils";

const ColorPicker: React.FC = () => {
  const dispatch = useAppDispatch();
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const userCanvasPrefs = useAppSelector(getUserCanvasPrefs);
  const [color, setColor] = useState<ColorResult>({
    rgb: {
      a: 1,
      ...hexToRgb(userCanvasPrefs.hexColor),
    },
    hex: userCanvasPrefs.hexColor,
    hsl: {
      a: 1,
      ...hexToHsl(userCanvasPrefs.hexColor),
    },
  });
  const buttonRef = useRef<HTMLDivElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  // This can fire many times, if user drags on color panel.
  const handleChange = (color: ColorResult) => {
    setColor(color);
  };

  // This fires fewer times, if user drag finished, or stagnant at 1 point.
  // Using this for redux.
  const handleChangeComplete = (color: ColorResult) => {
    dispatch(setUserCanvasPrefs({ hexColor: color.hex }));
  };

  const handleOutsideClick = (e: MouseEvent | PointerEvent) => {
    if (
      !buttonRef.current?.contains(e.target as Node) &&
      !pickerRef.current?.contains(e.target as Node)
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    // @ts-ignore
    document.addEventListener("pointerdown", handleOutsideClick);
    return () => {
      // @ts-ignore
      document.removeEventListener("pointerdown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative">
      <div
        ref={buttonRef}
        className="flex items-center rounded-md p-2 cursor-pointer border-2  border-slate-800 hover:border-slate-300 focus:border-slate-300 active:border-slate-800"
        style={{ backgroundColor: color.hex }}
        onClick={handleClick}
      >
        <div className="w-[5px] h-[5px] md:w-4 md:h-4 rounded-full" />
      </div>
      {displayColorPicker && (
        <div
          ref={pickerRef}
          className="absolute top-auto right-0 lg:left-0 z-10"
          style={{
            top: buttonRef.current
              ? `${buttonRef.current.offsetTop + 50}px`
              : "auto",
          }}
        >
          <CompactPicker
            color={color.rgb}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
