export const BE_URL =
  process.env.NODE_ENV === "production" ? "https://api.drawdetective.com" : "http://localhost:4000";

export const EVENTS = {
  CONNECTION: "connection",
  CONNECT: "connect",
  ON_CONNECTED: "on_connected",
  DISCONNECT: "disconnect",
  DISCONNECTING: "disconnecting",
  ON_DISCONNECTED: "on_disconnected",
  SEND_DRAW_LINEDATA: "send_draw_linedata",
  CLEAR_DRAW_DATA: "clear_draw_data",
  UNDO_DRAW_DATA: "undo_draw_data",
  REQUEST_TOTAL_DRAW_DATA: "request_total_draw_data",
  CHAT_MESSAGE: "chat_message",
  GAME_ERROR: "game_error",
  START_GAME: "start_game",
  DATA_UPDATES: "data_updates",
  START_TURN: "start_turn",
  REQUEST_UPDATE: "request_update",
  UPDATE_GAME_SETTINGS: "update_game_settings",
  LEAVE_GAME: "leave_game",
  JOIN_ROOM: "join_room",
  UPDATE_USER: "update_user"
};

export enum RoomVisibility {
  PUBLIC, PRIVATE
}

export enum RoomStatus {
  CREATED, RUNNING, IDLE, FINISHED
}

export enum GameStatus {
  CREATED, RUNNING, IDLE, FINISHED
}

export enum TurnStatus {
  CREATED, PREPPED, STARTED, COMPLETED, CLOSED
}

export enum GameType {
  PICTOPARTY
}

export enum PaintType {
  BRUSH, ERASER
}

export enum PowerType {
  SCORE_MULT
}

export enum PlayerStatus {
  CONNECTED, DISCONNECTED
}

export enum MessageType {
  PLAYER_CONNECTED, PLAYER_DISCONNECTED, PLAYER_REJOINED, PLAYER_LEFT,
  PLAYER_CHOOSING_WORD, PLAYER_DRAWING, WORD_REVEAL,
  NORMAL_CHAT, CHAT_AFTER_GUESSING, CORRECT_GUESS, PROFANITY,
  GAME_ENDED, ROOM_ADMIN_SET
}

export enum GameError {
  NO_ACTIVE_ROOM, NO_ACTIVE_GAME, NO_ACTIVE_TURN, MISSING_USERNAME, MISSING_AVATAR, WRONG_DRAWER,
  MIN_PLAYER_LIMIT, MAX_PLAYER_LIMIT, NO_ADMIN_RIGHTS
}

export enum WordDifficulty {
  EASY, MEDIUM, HARD, MIXED
}

export enum WordCreator {
  SERVER, PLAYER
}

export enum GameDataKeys {
  SETTINGS, TURN_DATA, SCORES, TURN_SUMMARY
}

export const getGameStatusLabel = (status: GameStatus | undefined) => {
  switch (status) {
    case GameStatus.CREATED: return "Created"
    case GameStatus.IDLE: return "Idle"
    case GameStatus.RUNNING: return "Running"
    case GameStatus.FINISHED: return "Finished"
    default: return "N/A"
  }
}

export const getTurnStatusLabel = (status: TurnStatus | null) => {
  switch (status) {
    case TurnStatus.CREATED: return "Created"
    case TurnStatus.PREPPED: return "Prepared"
    case TurnStatus.STARTED: return "Running"
    case TurnStatus.COMPLETED: return "Completed"
    case TurnStatus.CLOSED: return "Closed"
    default: return "N/A"
  }
}

export const MIN_PLAYERS = 2;
export const DEFAULT_MAX_PLAYERS = 10;
export const MAX_PLAYERS = 20;
// todo: 👉 Decide HINT min, max and default duration
export const MAX_HINT_DELAY = 100;
export const MIN_HINT_DELAY = 40;
export const MAX_AVATAR_ICONS_DEFINED = 45;
export const UNKNOWN_IMG_ID = 0;

export const NO_OF_ROUNDS = 4;
export const MIN_NO_OF_ROUNDS = 1;
export const MAX_NO_OF_ROUNDS = 10;

export const DRAWING_DURATION = 80;
export const MIN_DRAWING_DURATION = 20;
export const MAX_DRAWING_DURATION = 120;

export const WORD_SELECTION_DURATION = 15;
export const MIN_WORD_SELECTION_DURATION = 2;
export const MAX_WORD_SELECTION_DURATION = 10;

export const TURN_SUMMARY_DURATION = 4;

export const PREP_DURATION = 5;
export const MIN_PREP_DURATION = 2;
export const MAX_PREP_DURATION = 5;

export const CANVAS_WIDTH_PX = 800;
export const CANVAS_HEIGHT_PX = 600;
export const ID_ADMIN = 'admin'; // system's chat userID, name and also avatar file name (stored in public folder)
export const LINE_ID_LENGTH = 4; // ❗ todo: We dont necessarily need big lineIDs, just diff lineIDs for subsequent lines.

export const BRUSH_MAX_THICKNESS = 50;
export const BRUSH_MIN_THICKNESS = 2;

export const CLEANUP_DELAY = 5 * 60 * 1000;
export const GAME_IDLE_THRESHOLD = 3 * 60 * 1000;
export const MIN_PUBLIC_ROOMS = 5

export const BACKUP_DELAY = 5 * 60 * 1000;

export const MAX_SCORE = 125;
export const MIN_SCORE = 0.4 * 125;
export const DRAWER_SCORE_PERC = 15;
// Apply penalty beyond this time in seconds to give drawer some time to draw
export const TIME_PENALTY_THRESHOLD = 8;

export const CONFETTI_SHOW_DURATION = 4000;//ms
export const CONFETTI_FADE_DURATION = 1500;//ms

export const ALPHABET_REGEX = /^[a-zA-Z]+$/;
export const MASKED_CHARACTER_SYMBOL ='_';
export const SPACED_CHARACTER_SYMBOL =' ';
export const WORD_SEPARATOR_CHARACTER = ',';

export const ROOM_NAMES = [
  "Detective's Den", "Mystery Manor", "Clue Corner", "Sleuth's Sanctuary", "Surveillance Salon",
  "Inspector's Hideout", "Sherlock's Studio", "Riddle Room", "Spyglass Salon", "Whodunit Workshop",
  "Cipher Chamber", "Gumshoe Gallery", "Puzzle Parlor", "Noir Nook", "Evidence Emporium",
  "Investigator's Lair", "Detective's Dialogue", "Enigma Hall", "Interrogation Room", "Clue Conclave",
  "Sleuth's Study", "Secret Agent Salon", "Puzzle Palace", "Detective's Domain", "Mystery Mansion",
  "Spy Syndicate", "Clue Cave", "Inspector's Interlude", "Enigma Emporium",
]