import React, { useRef, useEffect, useState } from "react";
import gifshot from "gifshot";
import { getGifData, setGifImgData, useAppDispatch, useAppSelector } from "../../store/store";
import { GifFrame, GifFrameType, GifGameData, GifMessageData, GifRound, GifTurn, idMapType } from "../../../../../common/types";
import DrawSegment from "../../../../../domain/entities/DrawSegment";
import { drawLineSegment } from "../../common/utils";
import { UNKNOWN_IMG_ID } from "../../../../../common/Constants";
import Point from "../../../../../domain/entities/Point";
import LineSegment from "../../../../../domain/entities/LineSegment";
import { GIF_GUESSERS_POSITIONS, GIF_MESSAGE_POSITIONS } from "../../common/constants";
import { Spinner } from "flowbite-react";

const CANVAS_DRAWING_PORTION_WIDTH = 350;
const CANVAS_DRAWING_PORTION_HEIGHT = 262;
const CANVAS_DRAWING_PORTION_START_X = 40;
const CANVAS_DRAWING_PORTION_START_Y = 210;

type GifGeneratorButtonProps = {
  gameRoundsData: GifGameData | null;
  width: number;
  height: number;
  delay?: number;
};

const GifGeneratorButton: React.FC<GifGeneratorButtonProps> = ({
  gameRoundsData,
  width,
  height,
  delay = 2000,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [frameImages, setFrameImages] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const reduxGifData = useAppSelector(getGifData);
  const [processingGif, setProcessingGif] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !gameRoundsData || gameRoundsData.length === 0) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const drawFrame = async (ctx: CanvasRenderingContext2D, gameRoundsData: GifGameData, roundData: GifRound, turn: GifTurn, frame: GifFrame, images: string[], aggTurnShapes: DrawSegment[], frameNumber: number) => {
      clearCanvas(ctx, width, height);
      await drawCommon(ctx);
      if (frame.drawSegments) {
        aggTurnShapes.push(...anchorToCanvasStart(scaleToCanvasBound(frame.drawSegments, CANVAS_DRAWING_PORTION_WIDTH, CANVAS_DRAWING_PORTION_HEIGHT), CANVAS_DRAWING_PORTION_START_X, CANVAS_DRAWING_PORTION_START_Y));
      }
      drawShapes(ctx, aggTurnShapes);
      await drawDrawerAvatarAndName(ctx, turn, reduxGifData.idMap);
      drawRoundAndTurnNumber(ctx, roundData.round, turn.turn);
      if (frame.wordSoFar) {
        drawWordSoFar(ctx, frame.wordSoFar);
      }
      if (frame.type === GifFrameType.SUMMARY_OF_GUESSERS && frame.messages) {
        await drawGuessersAvatars(ctx, frame.messages, reduxGifData.idMap);
      } else {
        if (frame.messages) {
          await drawMessages(ctx, frame.messages, reduxGifData.idMap);
        }
      }
      // console.log({R_t:gameRoundsData.length,R_c:roundData.round - 1,T_t:roundData.turnsData.length,T_c: turn.turn - 1, T_prog:100*frameNumber/turn.frames.length});
      drawProgressBar(ctx, 580, gameRoundsData.length, roundData.round - 1, roundData.turnsData.length, turn.turn - 1, 100 * frameNumber / turn.frames.length, 'blue', 'red');
      images.push(canvas.toDataURL());
    };

    const images: string[] = [];
    const drawFrames = async () => {
      for (const roundData of gameRoundsData) {
        for (const turn of roundData.turnsData) {
          const aggTurnShapes: DrawSegment[] = [];
          let frameNumber = 0;
          for (const frame of turn.frames) {
            frameNumber++;
            await drawFrame(ctx, gameRoundsData, roundData, turn, frame, images, aggTurnShapes, frameNumber);
          }
        }
      }
      setFrameImages(images);
    };

    drawFrames();
  }, [gameRoundsData, width, height]);

  const generateGif = () => {
    console.log("Generate Gif of", frameImages);
    setProcessingGif(true);
    gifshot.createGIF(
      {
        images: frameImages,
        interval: delay / 1000,
        gifWidth: width,
        gifHeight: height,
      },
      (obj: any) => {
        if (!obj.error) {
          const image = obj.image;
          dispatch(setGifImgData(image));
          const a = document.createElement("a");
          a.href = image;
          a.download = "animation.gif";
          a.click();
          setProcessingGif(false);
        }
      }
    );
  };

  return (
    <div className="flex flex-col items-center">
      <canvas ref={canvasRef} width={width} height={height} className="hidden"></canvas>
      <button disabled={processingGif} onClick={generateGif} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition">
        {processingGif? <span>Generating... <Spinner size="sm" /></span>: <span>Generate GIF!</span>}
      </button>
    </div>
  );
};

export default GifGeneratorButton;

function clearCanvas(ctx: CanvasRenderingContext2D, width: number, height: number) {
  ctx.clearRect(0, 0, width, height);
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, width, height);
}

function drawRoundAndTurnNumber(ctx: CanvasRenderingContext2D, roundNumber: number, turnNumber: number) {
  drawText(ctx, "Round:" + roundNumber, 650, 50, 'red', '30px Arial');
  drawText(ctx, "Turn:" + turnNumber, 650, 100, 'red', '30px Arial');
}

function drawShapes(ctx: CanvasRenderingContext2D, drawSegments: DrawSegment[]) {
  drawSegments.forEach(({ cl, th, pt, seg }) => {
    seg.forEach((line) =>
      drawLineSegment(ctx, line.i, line.f, cl, th, pt)
    );
  });
}

async function drawMessages(ctx: CanvasRenderingContext2D, messages: GifMessageData[], idMap: idMapType): Promise<void> {
  drawTextInBox(ctx, "Guesses:", GIF_GUESSERS_POSITIONS[0][0] - 10, GIF_GUESSERS_POSITIONS[0][1] - 70, 280, 30, 'green', '20px Arial');
  for (let i = 0; i < messages.length; i++) {
    const msg = messages[i];
    const avatarUrl = (idMap[msg.senderId] ? idMap[msg.senderId].avatarId : UNKNOWN_IMG_ID) + ".svg";
    const userName = idMap[msg.senderId] ? idMap[msg.senderId].name : "Anon";
    // draw text box:
    drawRoundedRect(ctx, GIF_MESSAGE_POSITIONS[i][0] - 60, GIF_MESSAGE_POSITIONS[i][1] - 35, 350, 120, 20, 'LightGray', 'black', 2);
    // draw content:
    drawTextInBox2(ctx, msg.message, GIF_MESSAGE_POSITIONS[i][0], GIF_MESSAGE_POSITIONS[i][1], 280, 80, 'black', '20px Arial');
    drawTextInBox(ctx, userName, GIF_MESSAGE_POSITIONS[i][0], GIF_MESSAGE_POSITIONS[i][1] - 30, 280, 30, 'blue', '20px Arial');
    await drawAvatar(ctx, avatarUrl, GIF_MESSAGE_POSITIONS[i][0] - 55, GIF_MESSAGE_POSITIONS[i][1] - 30, 50, 50);
  }
}

async function drawGuessersAvatars(ctx: CanvasRenderingContext2D, messages: GifMessageData[], idMap: idMapType) {
  drawTextInBox(ctx, "Guessed Correctly!", GIF_GUESSERS_POSITIONS[0][0] - 10, GIF_GUESSERS_POSITIONS[0][1] - 70, 280, 30, 'green', '20px Arial');
  for (let i = 0; i < messages.length; i++) {
    let msg = messages[i];
    const avatarUrl = (idMap[msg.senderId] ? idMap[msg.senderId].avatarId : UNKNOWN_IMG_ID) + ".svg";
    const userName = idMap[msg.senderId] ? idMap[msg.senderId].name : "Anon";
    drawTextInBox(ctx, '✅' + userName, GIF_GUESSERS_POSITIONS[i][0], GIF_GUESSERS_POSITIONS[i][1] - 30, 280, 30, 'blue', '20px Arial');
    await drawAvatar(ctx, avatarUrl, GIF_GUESSERS_POSITIONS[i][0] - 55, GIF_GUESSERS_POSITIONS[i][1] - 30, 50, 50);
  }
}

function drawWordSoFar(ctx: CanvasRenderingContext2D, wordSoFar: string): void {
  // Draw in center of Canvas
  const displayWord = wordSoFar.toLocaleLowerCase().split('').join(' ');
  const centeredPosition = calculateCenterPosition(ctx, displayWord, '30px Courier New');
  drawText(ctx, displayWord, centeredPosition.posX, 100, 'black', '30px Courier New');
}

function drawRoundedRect(
  ctx: CanvasRenderingContext2D, startX: number, startY: number, width: number, height: number, borderRadius: number, fillColor: string = 'gray', strokeColor: string = 'black', borderThickness: number = 5): void {
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(startX + borderRadius, startY);
  ctx.lineTo(startX + width - borderRadius, startY);
  ctx.quadraticCurveTo(startX + width, startY, startX + width, startY + borderRadius);
  ctx.lineTo(startX + width, startY + height - borderRadius);
  ctx.quadraticCurveTo(startX + width, startY + height, startX + width - borderRadius, startY + height);
  ctx.lineTo(startX + borderRadius, startY + height);
  ctx.quadraticCurveTo(startX, startY + height, startX, startY + height - borderRadius);
  ctx.lineTo(startX, startY + borderRadius);
  ctx.quadraticCurveTo(startX, startY, startX + borderRadius, startY);
  ctx.closePath();

  // Fill the rectangle
  ctx.fillStyle = fillColor;
  ctx.fill();

  // Stroke the rectangle
  ctx.lineWidth = borderThickness;
  ctx.strokeStyle = strokeColor;
  ctx.stroke();

  ctx.restore();
}


function calculateCenterPosition(ctx: CanvasRenderingContext2D, wordSoFar: string, font: string) {
  ctx.font = font;
  const textMetrics = ctx.measureText(wordSoFar);

  const textWidth = textMetrics.width;
  const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;

  const canvasWidth = ctx.canvas.width;
  const canvasHeight = ctx.canvas.height;

  const posX = (canvasWidth - textWidth) / 2;
  const posY = (canvasHeight + textHeight) / 2;

  return { posX, posY };
}

async function drawCommon(ctx: CanvasRenderingContext2D) {
  // bg graident:
  drawBackground(ctx);
  // Logo:
  const pos = calculateCenterPosition(ctx, "Draw Detective", '30px Comic Sans');
  // Logo Image:
  await drawAvatar(ctx, 'admin.svg', pos.posX - 60, 10, 50, 50);
  // Logo Text:
  drawText(ctx, "Draw Detective", pos.posX, 50, 'blue', '40px Arial');
  drawTextInBox(ctx, '👉 drawdetective.com', 10, 10, 300, 30, 'gray', '15px Arial');
  // Canvas Area Bounds:
  drawRoundedRect(ctx, CANVAS_DRAWING_PORTION_START_X, CANVAS_DRAWING_PORTION_START_Y, CANVAS_DRAWING_PORTION_WIDTH, CANVAS_DRAWING_PORTION_HEIGHT, 2, 'gray', 'green', 2);
}

function drawBackground(ctx: CanvasRenderingContext2D) {
  const canvasHeight = ctx.canvas.height;
  const canvasWidth = ctx.canvas.width;
  const gradient = ctx.createRadialGradient(canvasWidth / 2, canvasHeight / 2, 1, canvasWidth / 2, canvasHeight / 2, canvasWidth * 5 / 4 / 1.5);
  gradient.addColorStop(0, '#ffeda0');
  gradient.addColorStop(1, '#ffa585');
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);
}

async function drawDrawerAvatarAndName(ctx: CanvasRenderingContext2D, turn: GifTurn, idMap: idMapType) {
  const avatarUrl = (idMap[turn.drawerId] ? idMap[turn.drawerId].avatarId : UNKNOWN_IMG_ID) + ".svg";
  const userName = idMap[turn.drawerId] ? idMap[turn.drawerId].name : "Anon";
  await drawAvatar(ctx, avatarUrl, 40, 150, 50, 50);
  drawTextInBox2(ctx, userName, 100, 150, 280, 50, 'blue', '20px Arial');
}

function drawText(ctx: CanvasRenderingContext2D, text: string, posX: number, posY: number, textColor: string = 'black', font: string = '20px Arial') {
  ctx.fillStyle = textColor;
  ctx.font = font;
  ctx.fillText(text, posX, posY);
}

async function drawAvatar(ctx: CanvasRenderingContext2D, avatarUrl: string, posX: number, posY: number, sizeXPx: number, sizeYPx: number): Promise<void> {
  return new Promise<void>((resolve) => {
    const avatarImg = new Image();
    avatarImg.src = avatarUrl;
    avatarImg.onload = () => {
      ctx.drawImage(avatarImg, posX, posY, sizeXPx, sizeYPx);
      resolve();
    };
  });
}

function scaleToCanvasBound(shapes: DrawSegment[], width: number, height: number): DrawSegment[] {
  const scaleX = width / 800;
  const scaleY = height / 600;

  return shapes.map(ds => {
    const scaledSegments = ds.seg.map(seg => {
      const scaledInitial = new Point(seg.i.x * scaleX, seg.i.y * scaleY);
      const scaledFinal = new Point(seg.f.x * scaleX, seg.f.y * scaleY);
      return new LineSegment(scaledInitial, scaledFinal);
    });

    return new DrawSegment(
      ds.cl,
      ds.th * Math.min(scaleX, scaleY),
      ds.pt,
      ds.dId,
      scaledSegments,
      ds.sId
    );
  });
}

function anchorToCanvasStart(shapes: DrawSegment[], startX: number, startY: number): DrawSegment[] {
  return shapes.map(ds => {
    const anchoredSegments = ds.seg.map(seg => {
      const anchoredInitial = new Point(seg.i.x + startX, seg.i.y + startY);
      const anchoredFinal = new Point(seg.f.x + startX, seg.f.y + startY);
      return new LineSegment(anchoredInitial, anchoredFinal);
    });

    return new DrawSegment(
      ds.cl,
      ds.th,
      ds.pt,
      ds.dId,
      anchoredSegments,
      ds.sId
    );
  });
}

function drawTextInBox(ctx: CanvasRenderingContext2D, text: string, startX: number, startY: number, boxWidth: number, boxHeight: number, textColor: string = 'black', font: string = "20px Arial"): void {
  ctx.fillStyle = textColor;
  ctx.font = font;
  const lineHeight = parseInt(font, 10) * 1.2; // Adjusting line height for better spacing

  const words = text.split(' ');
  let line = '';
  let lines: string[] = [];

  for (let i = 0; i < words.length; i++) {
    let testLine = line + words[i] + ' ';
    let testWidth = ctx.measureText(testLine).width;

    if (testWidth > boxWidth) {
      // Handle word breaking for very long words
      if (ctx.measureText(words[i]).width > boxWidth) {
        let splitWord = words[i];
        while (ctx.measureText(splitWord).width > boxWidth) {
          let testWord = splitWord.slice(0, -1);
          if (ctx.measureText(testWord + '-').width <= boxWidth) {
            lines.push(testWord + '-');
            splitWord = splitWord.slice(testWord.length);
          } else {
            break;
          }
        }
        words[i] = splitWord;
      }

      lines.push(line.trim());
      line = words[i] + ' ';
    } else {
      line = testLine;
    }
  }

  lines.push(line.trim());

  // Handle ellipsis
  const ellipsis = '...';
  let lineCount = Math.floor(boxHeight / lineHeight);

  if (lines.length > lineCount) {
    lines = lines.slice(0, lineCount);
    while (ctx.measureText(lines[lines.length - 1] + ellipsis).width > boxWidth) {
      lines[lines.length - 1] = lines[lines.length - 1].slice(0, -1);
    }
    lines[lines.length - 1] = lines[lines.length - 1] + ellipsis;
  }

  // Draw each line of text within the specified box
  for (let i = 0; i < lines.length; i++) {
    ctx.fillText(lines[i], startX, startY + lineHeight * (i + 1));
  }
}

function drawTextInBox2(ctx: CanvasRenderingContext2D, text: string, startX: number, startY: number, boxWidth: number, boxHeight: number, textColor: string = 'black', font: string = "20px Arial"): void {
  ctx.fillStyle = textColor;
  ctx.font = font;
  const lineHeight = parseInt(font, 10) * 1.2; // Adjusting line height for better spacing

  const words = text.split(' ');
  let line = '';
  let lines: string[] = [];

  words.forEach(word => {
    if (ctx.measureText(word).width > boxWidth) {
      // Handle very long words that exceed the box width
      let partialWord = '';
      for (let char of word) {
        if (ctx.measureText(partialWord + char).width > boxWidth) {
          lines.push(partialWord + '-');
          partialWord = char;
        } else {
          partialWord += char;
        }
      }
      line = partialWord + ' ';
    } else {
      let testLine = line + word + ' ';
      let testWidth = ctx.measureText(testLine).width;

      if (testWidth > boxWidth && line !== '') {
        lines.push(line);
        line = word + ' ';
      } else {
        line = testLine;
      }
    }
  });

  lines.push(line.trim());

  // Handle ellipsis
  const ellipsis = '...';
  const ellipsisWidth = ctx.measureText(ellipsis).width;
  let lineCount = Math.floor(boxHeight / lineHeight);

  if (lines.length > lineCount) {
    lines = lines.slice(0, lineCount);
    while (ctx.measureText(lines[lines.length - 1] + ellipsis).width > boxWidth) {
      lines[lines.length - 1] = lines[lines.length - 1].slice(0, -1);
    }
    lines[lines.length - 1] += ellipsis;
  }

  // Draw each line of text within the specified box
  for (let i = 0; i < lines.length; i++) {
    ctx.fillText(lines[i], startX, startY + lineHeight * (i + 1));
  }
}

function drawProgressBar(ctx: CanvasRenderingContext2D, startY: number, rounds: number, currentRound: number, turnsInCurrentRound: number, currentTurn: number,
  turnProgress: number, // Progress of the current turn in percentage (0-100)
  roundColor: string, activeTurnColor: string
) {
  const TURN_SPACING = 3; // Spacing between turns
  const ROUND_SPACING = 10;
  const totalRoundSpacing = ROUND_SPACING * (rounds - 1);
  const roundWidth = (ctx.canvas.width - totalRoundSpacing) / rounds;
  const roundHeight = 10;

  for (let i = 0; i < rounds; i++) {
    const roundX = i * (roundWidth + ROUND_SPACING);

    if (i < currentRound) {
      // Draw completed rounds
      ctx.fillStyle = roundColor;
      ctx.fillRect(roundX, startY, roundWidth, roundHeight);
    } else if (i === currentRound) {
      // Draw current round with turn subdivisions
      for (let j = 0; j < turnsInCurrentRound; j++) {
        const turnWidth = roundWidth / turnsInCurrentRound;
        const turnX = roundX + j * (turnWidth + TURN_SPACING); // Adjusted for turn spacing

        if (j < currentTurn) {
          // Draw completed turns
          ctx.fillStyle = roundColor;
          ctx.fillRect(turnX, startY, turnWidth, roundHeight);
        } else if (j === currentTurn) {
          // Draw current turn with progress
          ctx.fillStyle = roundColor;
          ctx.fillRect(turnX, startY, turnWidth * (turnProgress / 100), roundHeight);
          ctx.fillStyle = activeTurnColor;
          ctx.fillRect(
            turnX + turnWidth * (turnProgress / 100),
            startY,
            turnWidth - turnWidth * (turnProgress / 100),
            roundHeight
          );
        } else {
          // Draw future turns
          ctx.fillStyle = activeTurnColor;
          ctx.fillRect(turnX, startY, turnWidth, roundHeight);
        }
      }
    } else {
      // Draw future rounds
      ctx.fillStyle = activeTurnColor;
      ctx.fillRect(roundX, startY, roundWidth, roundHeight);
    }
  }
}