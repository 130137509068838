import { useEffect, useRef, useState } from 'react';
import { FaCircle } from "react-icons/fa";
import MyButton from '../ui/Button';
import { getUserCanvasPrefs, setUserCanvasPrefs, useAppDispatch, useAppSelector } from '../../store/store';
import { BRUSH_MAX_THICKNESS, BRUSH_MIN_THICKNESS } from '../../../../../common/Constants';

function BrushThickness() {
    const dispatch = useAppDispatch();
    const userCanvasPrefs = useAppSelector(getUserCanvasPrefs);
    const [brushThickness, setBrushThickness] = useState(userCanvasPrefs.lineWidth);
    const [showBrushThickness, setShowBrushThickness] = useState(false);
    const brushRef = useRef<HTMLDivElement>(null);

    const handleBrushThicknessChange = (value:string) => {
        const intValue = parseInt(value);
        if (!intValue) return;

        setBrushThickness(intValue);
        dispatch(setUserCanvasPrefs({lineWidth: intValue}));
    };

    const handlePointerDownOutside = (event: PointerEvent) => {
        if (brushRef.current && !brushRef.current.contains(event.target as Node)) {
            setShowBrushThickness(false);
        }
    };

    useEffect(() => {
        if (showBrushThickness) {
            document.addEventListener('pointerdown', handlePointerDownOutside);
        } else {
            document.removeEventListener('pointerdown', handlePointerDownOutside);
        }

        return () => {
            document.removeEventListener('pointerdown', handlePointerDownOutside);
        };
    }, [showBrushThickness]);

    return (
        <MyButton primary onClick={() => setShowBrushThickness(!showBrushThickness)}>
            <div className='relative h-3 w-3 md:h-6 md:w-6 flex place-items-center justify-around'>
                <FaCircle className='h-2 w-2 md:h-3 md:h-3' />
                {showBrushThickness && (
                    <div ref={brushRef} className={`absolute top-full transform translate-y-1/3 translate-x-1/3 bg-white p-2 rounded shadow-md z-10`}>
                        <input type="range" min={BRUSH_MIN_THICKNESS} max={BRUSH_MAX_THICKNESS} value={brushThickness} onChange={(e) => handleBrushThicknessChange(e.target.value)} />
                    </div>
                )}
            </div>
        </MyButton>
    );
}

export default BrushThickness;