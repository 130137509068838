import { PaintType } from "../../common/Constants";
import LineSegment from "./LineSegment";

/**
 * To reduce the data transfer, we abbreviated these keys
 * color - cl
 * thickness - th
 * paintType - pt
 * drawerId - dId
 * segments - seg
 * updatedAt = uAt
 * segmentId = sId
 */
class DrawSegment {
    cl: string;
    th: number;
    pt: PaintType;
    dId: string;
    seg: LineSegment[];
    uAt?: number;
    sId: string;

    constructor(color: string, thickness: number, paintType: PaintType, drawerId: string, lineSegments: LineSegment[], segmentId: string, updatedAt?: number) {
        this.cl = color;
        this.th = thickness;
        this.pt = paintType;
        this.dId = drawerId;
        this.seg = lineSegments;
        this.sId = segmentId;
        this.uAt = updatedAt;
    }
}

export default DrawSegment;