import { isUserTheDrawerForThisTurn } from "../common/utils";
import GameHeader from "./GameHeader";
import UserListSection from "./UserListSection";
import CanvasSection from "./canvas/CanvasSection";
import CanvasTools from "./canvas/CanvasTools";
import ChatSection from "./chat/ChatSection";
import { useViewportHeight } from "../common/useViewportHeight";
import { getGameData, useAppSelector } from "../store/store";
import { TurnStatus } from "../../../../common/Constants";

export function Layout() {
  const vh = useViewportHeight();
  const gameData = useAppSelector(getGameData);

  return (
    <div className="max-w-screen-xl h-dvh antialiased mx-auto" style={{ height: `calc(${vh}px * 100)` }}>
      <div className="container grid grid-cols-10 grid-rows-[55px_auto_1fr] lg:grid-rows-[55px_1fr_1fr] xl:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr] xl:grid-rows-[55px_1fr_1fr]  h-full mx-auto">
        <div className="order-first xl:order-1 col-span-10 bg-blue-600">
          <GameHeader />
        </div>
        <div className="order-2 col-span-full lg:col-span-7 lg:row-span-2 bg-blue-400">
          <div className="flex flex-col p-1 bg-purple-400 overflow-auto">
            <div className="flex flex-row place-items-center">
              {isUserTheDrawerForThisTurn() && gameData?.turnData?.status === TurnStatus.STARTED &&
              <div className="p-1 h-fit bg-slate-200 border-2 border-gray-800 flex justify-around">
                <CanvasTools />
              </div>}
              <CanvasSection />
            </div>
          </div>
        </div>
        <div className="p-1 order-3 min-h-[56px] col-span-4 lg:col-span-3 lg:row-span-1 bg-red-500 h-full">
          <UserListSection />
        </div>
        <div className="p-1 order-4 min-h-[56px] col-span-6 lg:col-span-3 lg:row-span-1 bg-blue-500">
          <ChatSection />
        </div>
        {/* <div className="p-1 order-5 xl:order-first col-span-full lg:row-span-1 xl:col-span-1 xl:row-span-full bg-green-300">
          Static Ad
        </div> */}
      </div>
    </div>
  );
}

function Game() {
  return <Layout />;
}

export default Game;
