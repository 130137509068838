import GifDisplay from "./GifDisplay";
import GifGeneratorButton from "./GifGeneratorButton";
import GifDataPopulator from "./GifDataPopulator";
import { getGifData, useAppSelector } from "../../store/store";
import BuyMeACoffeeButton from "../BuyMeCoffeeButton";

function GifContainer() {
  const gifData = useAppSelector(getGifData);
  return (
    <div className="flex flex-col gap-2 justify-center items-center">
      <GifDataPopulator />
      <GifGeneratorButton gameRoundsData={gifData.toDraw} width={800} height={600} />
      <GifDisplay />
      <BuyMeACoffeeButton/>
    </div>
  );
}

export default GifContainer;
