import { IoTrashBinSharp } from "react-icons/io5";
import { FaEraser, FaPaintBrush } from "react-icons/fa";
import { IoArrowUndo } from "react-icons/io5";
import MyButton from '../ui/Button';
import { getUserCanvasPrefs, requestRepaintAndResetToDrawQueueAndAndAddTheseEdges, toggleBrushOrEraserPref, useAppDispatch, useAppSelector } from "../../store/store";
import { socketClearDrawData, socketUndoDrawData } from "../../socket";
import ColorPicker from "./ColorPicker";
import BrushThickness from "./BrushThicknness";
import { PaintType } from "../../../../../common/Constants";
import { isUserTheDrawerForThisTurn } from "../../common/utils";
import { Tooltip } from "flowbite-react";


function CanvasTools() {

  const userCanvasPrefs = useAppSelector(getUserCanvasPrefs);
  const dispatch = useAppDispatch();

  function onClickClearDrawData() {
    dispatch(requestRepaintAndResetToDrawQueueAndAndAddTheseEdges([]));
    socketClearDrawData();
  }

  function onSelectEraser(){
    if(userCanvasPrefs.eraserOrBrushSelected === PaintType.ERASER) return;
    dispatch(toggleBrushOrEraserPref());
  }

  function onSelectBrush(){
    if(userCanvasPrefs.eraserOrBrushSelected === PaintType.BRUSH) return;
    dispatch(toggleBrushOrEraserPref());
  }

  const isUserDrawer = isUserTheDrawerForThisTurn();

  return (
    <>
    <div className="flex flex-col h-full flex-wrap gap-1 md:gap-4 text-xs text-gray-800 items-center">

        <div className="flex flex-col">
          <div className="flex flex-col place-items-center">
            <MyButton primary disabled={userCanvasPrefs.eraserOrBrushSelected === PaintType.BRUSH} onClick={onSelectBrush} className={`${userCanvasPrefs.eraserOrBrushSelected === PaintType.BRUSH ? "border-2 border-slate-900":""}`}>
              <div className='h-[12px] w-[12px] md:h-6 md:w-6  flex place-items-center justify-around'>
                <FaPaintBrush className="h-[12px] w-[12px] md:h-6 md:w-6 "/>
              </div>
            </MyButton>
            <p className={`${userCanvasPrefs.eraserOrBrushSelected === PaintType.BRUSH ? "font-bold":""}`}>Brush</p>
          </div>
          
          <div className="flex flex-col place-items-center">
            <MyButton primary disabled={userCanvasPrefs.eraserOrBrushSelected === PaintType.ERASER} onClick={onSelectEraser} className={`${userCanvasPrefs.eraserOrBrushSelected === PaintType.ERASER ? "border-2 border-slate-900":""}`}>
              <div className={`h-[12px] w-[12px] md:h-6 md:w-6  flex place-items-center justify-around`}>
                <FaEraser className="h-[12px] w-[12px] md:h-6 md:w-6 "/>
              </div>
            </MyButton>
            <p  className={`${userCanvasPrefs.eraserOrBrushSelected === PaintType.ERASER ? "font-bold":""}`}>Eraser</p>
          </div>
        </div>
        
        <div className="flex flex-col gap-0">
          <div className="flex flex-col place-items-center">
            <BrushThickness />
            <p>Size</p>
          </div>

          <div className="flex flex-col place-items-center">
            <ColorPicker/>
            <p>Color</p>
          </div>
        </div>
        
        <div className="flex flex-col gap-0">
          <div className="flex flex-col place-items-center">
            {!isUserDrawer ?
              <Tooltip content={"You are not the drawer!"} >
                <MyButton disabled={!isUserDrawer} primary onClick={socketUndoDrawData}><div className='h-[12px] w-[12px] md:h-6 md:w-6  flex place-items-center justify-around'><IoArrowUndo className='h-[12px] w-[12px] md:h-6 md:w-6 '/></div></MyButton>
                <p>Undo</p>
              </Tooltip>
              :
              <>
                <MyButton disabled={!isUserDrawer} primary onClick={socketUndoDrawData}><div className='h-[12px] w-[12px] md:h-6 md:w-6  flex place-items-center justify-around'><IoArrowUndo className='h-[12px] w-[12px] md:h-6 md:w-6 '/></div></MyButton>
                <p>Undo</p>
              </>
            }
          </div>

          <div className="flex flex-col place-items-center">
          {!isUserDrawer ?
            <Tooltip content={"You are not the drawer!"}>
              <MyButton disabled={!isUserDrawer} primary onClick={onClickClearDrawData}><IoTrashBinSharp className='h-[12px] w-[12px] md:h-6 md:w-6 '/></MyButton>
              <p>Clear</p>
            </Tooltip>
            :
            <>
              <MyButton disabled={!isUserDrawer} primary onClick={onClickClearDrawData}><IoTrashBinSharp className='h-[12px] w-[12px] md:h-6 md:w-6 '/></MyButton>
              <p>Clear</p>
            </>
          }
          </div>
        </div>
        
    </div>
    </>
  )
}

export default CanvasTools