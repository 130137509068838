import { useEffect, useState } from 'react'
import Confetti from 'react-confetti';

import { CONFETTI_FADE_DURATION, CONFETTI_SHOW_DURATION } from '../../../../../common/Constants';

interface ConfettiComponentProps {
  showConfetti: boolean;
}

const ConfettiComponent: React.FC<ConfettiComponentProps> = ({
  showConfetti
}) => {
  const [isConfettiVisible, setIsConfettiVisible] = useState<boolean>(false);
  const [isFadingOut, setIsFadingOut] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let fadeTimeout: NodeJS.Timeout;
    if (showConfetti) {
      setIsConfettiVisible(true);
      setIsFadingOut(false);
      timeout = setTimeout(() => {
        setIsFadingOut(true);
        fadeTimeout = setTimeout(() => {
          setIsConfettiVisible(false);
        }, CONFETTI_FADE_DURATION);
      }, CONFETTI_SHOW_DURATION);
    }

    return () => {
      clearTimeout(timeout);
      clearTimeout(fadeTimeout);
    };
  }, [showConfetti, CONFETTI_FADE_DURATION, CONFETTI_SHOW_DURATION]);

  return isConfettiVisible ? (
    <div className={`fixed inset-0 ${isFadingOut ? 'opacity-0 transition-opacity duration-1000' : 'opacity-100'} pointer-events-none`}>
      <Confetti />
    </div>
  ) : null;
};

export default ConfettiComponent;