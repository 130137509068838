import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { leaveGame } from "../../socket";

const LogoWithWarningModal = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div onClick={() => setOpenModal(true)} className="h-fit w-fit"><img src="dd_logo.png" alt="Draw Detective logo" className="h-10 w-10 cursor-pointer m-1"/></div>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
        dismissible
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Sure, you want to leave this game?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => {
                    setOpenModal(false);
                    leaveGame();
                    navigate("/");
                }}>
                {"Yes"}
              </Button>
              <Button color="gray" onClick={() => setOpenModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LogoWithWarningModal;
