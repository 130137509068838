import React from "react";
import MyButton from "../ui/Button";
import { getDrawerId, useAppSelector } from "../../store/store";
import { getUserFromUserId } from "../../common/utils";

interface WordChooseProps {
  words?: string[];
  onItemClick: (index: number) => void;
  isDrawer: boolean;
}

const WordChoose: React.FC<WordChooseProps> = ({ words, onItemClick, isDrawer }) => {
  const handleItemClick = (index: number) => {
    onItemClick(index);
  };
  const drawerId = useAppSelector(getDrawerId);
  const drawerName = !drawerId ?  "Drawer" : getUserFromUserId(drawerId)?.userName || "Drawer";

  return (
      <div className="flex flex-col mx-auto h-fit w-fit items-center">
        {isDrawer?<div>Choose A Word</div>:<div><span className="text-green-600 motion-safe:animate-pulse font-bold">{drawerName}</span> is choosing a word!</div>}
          <ul className="flex flex-row flex-wrap gap-4 p-2 m-2">
            {words && words.map((word, index) => (
              <MyButton type="button"
                primary rounded
                key={index}
                onClick={() => handleItemClick(index)}
              >
                {word}
              </MyButton>
            ))}
          </ul>
      </div>
  );
};

export default WordChoose;
