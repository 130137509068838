import { io, Socket } from "socket.io-client";
import { toast } from "react-toastify";

import { BE_URL, EVENTS, GameError } from "../../../common/Constants";
import { getUserAvatarId, resetPictoPartyData, setGifGameData, setTurnSummaryData, setWordUpdate, store } from "./store/store";
import { addMessagesToMessageList, replaceMessagesListTotally } from "./store/store";
import { getUserId, getUserName, replaceUserListTotally, resetUserList } from "./store/store";
import { addEdgesToDrawQueue, requestRepaintAndResetToDrawQueueAndAndAddTheseEdges } from "./store/store";
import DataUpdates from "../../../domain/entities/DataUpdates";
import { replaceGameDataTotally, updateGameData } from "./store/store";
import { getRoomIdFromUrlParams } from "./common/constants";
import { ClientGameSetting, User } from "../../../common/types";
import DrawSegment from "../../../domain/entities/DrawSegment";
import { mapCanvasDataToDTO } from "../../../common/mappers";

// "undefined" means the URL will be computed from the `window.location` object
const URL = BE_URL;

export class AppSocket {
  private static instance: AppSocket;
  private socket: Socket;

  private constructor() {
    // Initialize the socket instance
    this.socket = io(URL, {
      auth: this.getLatestAuthParams(),
      autoConnect: false,
    });
  }

  public static initSocket() {
    AppSocket.getInstance();
  }

  public static getInstance(): AppSocket {
    if (!AppSocket.instance) {
      AppSocket.instance = new AppSocket();
      AppSocket.instance.registerListeners();
      AppSocket.instance.socket.connect();
    }
    return AppSocket.instance;
  }

  private registerListeners() {
    this.socket.on(EVENTS.ON_CONNECTED, (data) => {
      // console.log(
      //   `Someone connected. ${data.users.length} User list now:${data.users.join(
      //     ","
      //   )}; ${data.msgs.length} Msgs: ${data.msgs.map((d) => d.msg).join(",")}`
      // );
      //store.dispatch(requestRepaintAndResetToDrawQueueAndAndAddTheseEdges(data.canvas));
      throw new Error(`Event ${EVENTS.ON_CONNECTED} not configured on client.`);
    });

    this.socket.on(EVENTS.ON_DISCONNECTED, (data) => {
      // console.log(`Someone disconnected. User list now:${data.join(",")}`)
      store.dispatch(replaceUserListTotally(data));
    });

    this.socket.on(EVENTS.DATA_UPDATES, (updates: DataUpdates) => {
      if (updates.game) {
        if (updates.game.complete) {
          store.dispatch(replaceGameDataTotally(updates.game.data));
        } else {
          store.dispatch(updateGameData(updates.game.data));
        }
      }
      if (updates.players) {
        if (updates.players.complete) {
          store.dispatch(replaceUserListTotally(updates.players.data));
        } else {

        }
      }
      if (updates.chats) {
        if (updates.chats.complete) {
          store.dispatch(replaceMessagesListTotally(updates.chats.data));
        } else {
          if (updates.chats.data) {
            store.dispatch(addMessagesToMessageList(updates.chats.data));
          }
        }
      }
      if (updates.canvas) {
        // convert DTO to back.
        // console.log("receiving: ",updates.canvas.data);
        // console.log("receiving-Conv: ",mapDTOToCanvasData(updates.canvas.data));
        if (updates.canvas.complete) {

          store.dispatch(requestRepaintAndResetToDrawQueueAndAndAddTheseEdges(updates.canvas.data));
        } else {
          store.dispatch(addEdgesToDrawQueue(updates.canvas.data));
        }
      }
      if (updates.turnSummary) {
        store.dispatch(setTurnSummaryData(updates.turnSummary));
      }
      if (updates.word) {
        console.log("Word: ", updates.word);
        store.dispatch(setWordUpdate(updates.word));
      }
      if (updates.gifGameData) {
        // Expecting it to be complete data always
        console.log(updates.gifGameData);
        store.dispatch(setGifGameData(updates.gifGameData.data));
      }
    });

    this.socket.on(EVENTS.GAME_ERROR, (data: GameError) => {
      switch (data) {
        case GameError.MISSING_USERNAME:
          toast.error("Missing UserName");
          break;

        case GameError.MISSING_AVATAR:
          toast.error("Missing Avatar");
          break;

        case GameError.NO_ACTIVE_GAME:
          toast.error("No active game in this room");
          break;

        case GameError.NO_ACTIVE_ROOM:
          toast.error("This room is inactive or not found");
          break;

        case GameError.NO_ACTIVE_TURN:
          toast.error("This turn is not active");
          break;

        case GameError.WRONG_DRAWER:
          toast.error("Wrong drawer");
          break;

        case GameError.MIN_PLAYER_LIMIT:
          toast.error('Min players condition not met');
          break;

        case GameError.MAX_PLAYER_LIMIT:
          toast.error('Room is full');
          break;

        case GameError.NO_ADMIN_RIGHTS:
          toast.error('Admin rights are missing');
          break;
      }
    });
  }

  public getSocket(): Socket {
    return this.socket;
  }

  // Method to refresh auth parameters after socket initialization
  public refreshAndReconnect(cb: () => void): void {
    // Disconnect existing socket
    this.socket.disconnect();

    // Create new connection with updated params:
    this.socket = io(URL, {
      auth: this.getLatestAuthParams(),
      autoConnect: false,
    });

    // Register 'connect' event listener to invoke the callback once reconnected
    this.socket.on('connect', () => {
      // Remove the 'connect' event listener to avoid multiple invocations
      this.socket.off('connect');
      // Invoke the callback
      cb();
    });

    // Register listeners for new socket connections
    this.registerListeners();

    this.socket.connect();
  }

  private getLatestAuthParams() {
    return {
      userId: fetchUserId(),
      roomId: getRoomIdFromUrlParams(),
      userName: getUserName(store.getState()),
      avatarId: getUserAvatarId(store.getState()),
    };
  }
}

// All Socket Emitters:
export const socketSendChatMessage = (message: any) => {
  AppSocket.getInstance().getSocket().emit(EVENTS.CHAT_MESSAGE, getRoomIdFromUrlParams(), message, fetchUserId(), fetchUserName());
}

// todo: Not sure whether this is actually required, or SocketIO automatically handles this via 'disconnect' event.
export function socketDisconnect() {
  if (AppSocket.getInstance().getSocket().connected) {
    console.log("Disconnecting", `Socket ID: ${AppSocket.getInstance().getSocket().id}`);
    AppSocket.getInstance().getSocket().disconnect();
    store.dispatch(resetUserList());
  }
}

export function socketSendDrawLineData(data: DrawSegment[]) {
  // convert to DTO before sending on socket.
  // console.log("sending: ",data);
  // console.log("sending-conv: ",mapCanvasDataToDTO(data));
  AppSocket.getInstance().getSocket().emit(EVENTS.SEND_DRAW_LINEDATA, getRoomIdFromUrlParams(), mapCanvasDataToDTO(data));
}

export function socketClearDrawData() {
  AppSocket.getInstance()
    .getSocket()
    .emit(EVENTS.CLEAR_DRAW_DATA, getRoomIdFromUrlParams(), fetchUserId());
}

export function socketRequestTotalDrawData() {
  AppSocket.getInstance().getSocket().emit(EVENTS.REQUEST_TOTAL_DRAW_DATA, getRoomIdFromUrlParams());
}

export const startGame = () => {
  AppSocket.getInstance().getSocket().emit(EVENTS.START_GAME, getRoomIdFromUrlParams(), fetchUserId());
}

export const startTurn = (word: string) => {
  AppSocket.getInstance().getSocket().emit(EVENTS.START_TURN, getRoomIdFromUrlParams(), word);
}

export const socketUndoDrawData = () => {
  AppSocket.getInstance().getSocket().emit(EVENTS.UNDO_DRAW_DATA, getRoomIdFromUrlParams(), fetchUserId());
}

function fetchUserId() {
  return getUserId(store.getState());
}

function fetchUserName() {
  return getUserName(store.getState());
}

export const updateGameSettings = (settings: ClientGameSetting) => {
  AppSocket.getInstance().getSocket().emit(EVENTS.UPDATE_GAME_SETTINGS, getRoomIdFromUrlParams(), settings);
}

export const leaveGame = () => {
  AppSocket.getInstance().getSocket().emit(EVENTS.LEAVE_GAME, getRoomIdFromUrlParams());
  // 🤜 todo: 1 message still remains in chats (`user left the group`). Should we do clenup after room left. Using some room left event?
  resetPictoPartyData();
}

export const joinRoom = () => {
  // resetPictoPartyData(); ❗ Lets only clear data when leaving a room (not when joining room), as avatar and userName also gets cleared out, and joining room can fail on client.
  AppSocket.getInstance().getSocket().emit(EVENTS.JOIN_ROOM, getRoomIdFromUrlParams(), fetchUserId());
}

export const refreshAndReconnectSocket = (cb: () => void) => {
  AppSocket.getInstance().refreshAndReconnect(cb);
}

export const updateUserNameOrAvatar = (updatedUser: User) => {
  AppSocket.getInstance().getSocket().emit(EVENTS.UPDATE_USER, updatedUser);
}