import React from 'react'
import ReactDOM from 'react-dom/client'
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux'
import * as Sentry from "@sentry/react";
import App from './App.tsx'
import { store } from './store/store'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { printConsoleMessage } from './console_printer.ts';
import './index.css'
import 'react-toastify/dist/ReactToastify.css';

if (process.env.NODE_ENV === "production") {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyAPhayNsDk4CQ-DHoFTWMssmo2W-d2MTWg",
    authDomain: "pictoparty-c440f.firebaseapp.com",
    projectId: "pictoparty-c440f",
    storageBucket: "pictoparty-c440f.appspot.com",
    messagingSenderId: "334879556056",
    appId: "1:334879556056:web:8d137f0e98fbe66324f2ad",
    measurementId: "G-X81JVXKDG2"
  };

  Sentry.init({
    dsn: "https://062b357bfe7ea261a73f70df0c70e019@o4507610143326208.ingest.us.sentry.io/4507610148110336",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}

printConsoleMessage();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer stacked limit={2} />
    </Provider>
  </React.StrictMode>,
)
