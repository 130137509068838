import React from "react";
import { isUserTheDrawerForThisTurn, maskAndAddWordLengthAndCommaSeparator } from "../common/utils";
import { getGameData, getWord } from "../store/store";
import { useAppSelector } from "../store/store";
import Timer from "./TimerNumber";
import LogoWithWarningModal from "./ui/LogoWithWarningModal";
import { IoMdRefresh } from "react-icons/io";

function getWordRenderStyles(word:string[]) : string{
  if(!word || !word.length) return "";
  else if(word.length<=15) return "text-3xl";
  else if(word.length<=25) return "text-2xl";
  else if(word.length<=35) return "text-xl";
  else if(word.length<=45) return "text-lg";
  else return "text-base";
}

function GameHeader() {
  const gameData = useAppSelector(getGameData);
  const gameWordWithHint = useAppSelector(getWord);
  const isDrawer = isUserTheDrawerForThisTurn();
  const isLastRound = !!(gameData?.currentRound && gameData?.currentRound === gameData?.totalRounds);

  let renderWord:string[] = [];
  let wordLengths: number[] = [];
  if (gameWordWithHint && isDrawer){
    renderWord =  [gameWordWithHint];
  } else if(gameWordWithHint && !isDrawer) {
    const maskedWord = maskAndAddWordLengthAndCommaSeparator(gameWordWithHint);
    renderWord = maskedWord.maskedWords;
    wordLengths = maskedWord.wordLengths;
  }

  return (
    <div key='game-header' className="m-1 flex justify-between gap-1 items-center h-full w-full">
      <div key='container-round' className="flex gap-2 items-center">
        <LogoWithWarningModal />
        {/* Round x/y */}
        <div key='round'className="flex flex-col place-items-center rounded-lg p-1">
        <div key='refresh-icon'><IoMdRefresh /> </div>
        <div key='round-info'>
          <span key='current-round' className={`text-sm  ${isLastRound ? "font-extrabold motion-safe:animate-pulse text-red-300":""}`} style={{animationDuration: '.75s'}}>{gameData?.currentRound}</span>
          <span key='total-rounds' className={`text-sm ${isLastRound ? "motion-safe:animate-pulse text-red-400":""}`} style={{animationDuration: '.75s'}}>/{gameData?.totalRounds}</span>
        </div>
        </div>
      </div>
      {/* Draw/Guess Word (length) */}

      {gameWordWithHint && (
      <div key='container-word' className="w-4/6 gap-1 place-items-center text-center text-white rounded-lg p-1 z-50">
        <span key='word-label' className="text-base font-semibold">{isDrawer ? "Draw: " : "Guess: "}</span>
        <span key='word' className={`${getWordRenderStyles(renderWord)} text-center font-bold mx-1`}>
          {renderWord.map((wd,idx) => <React.Fragment key={`frag-${idx}`}><span key={'word-'+idx} className={`${isDrawer?"px-0":"px-2"}`}>{wd}</span>{!isDrawer ? <sup className="sup font-normal text-xs" key={'length-'+idx}>{wordLengths[idx]}</sup> : null}</React.Fragment>)}
        </span>
      </div>)}

      {/* Timer */}
      
      <Timer />
      {/* <MyButton
        onClick={() => {
          console.log("Printing Game Settings", gameData?.settings);
        }}
      >
        ❗<IoMdSettings />
      </MyButton> */}
      {/* <LeaveGameWithWarningModal /> */}
      
    </div>
  );
}

export default GameHeader;
