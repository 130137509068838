import { nanoid } from "nanoid";
import { PlayerStatus } from "../../../../common/Constants";

export const mockPlayerList = [
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: true,
    drawer: false,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: false,
    drawer: true,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: false,
    drawer: false,
    status: PlayerStatus.DISCONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: false,
    drawer: false,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: true,
    drawer: false,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: false,
    drawer: false,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: false,
    drawer: false,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: false,
    drawer: false,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: false,
    drawer: false,
    status: PlayerStatus.DISCONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: false,
    drawer: false,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: true,
    drawer: false,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: true,
    drawer: false,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: true,
    drawer: false,
    status: PlayerStatus.DISCONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
  {
    uid: nanoid(10),
    imgUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
    userName: nanoid(10),
    score: 123123,
    rank: 1,
    guessed: true,
    drawer: false,
    status: PlayerStatus.CONNECTED,
    socketId: nanoid(10),
    remainingPowerUps: {},
  },
];

export const mockLeaderboardData = [
  {
    rank: 1,
    name: "John",
    points: 100484,
    avatarUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
  },
  {
    rank: 2,
    name: "Kanyakumari",
    points: 90484,
    avatarUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
  },
  {
    rank: 3,
    name: "Bob",
    points: 70484,
    avatarUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
  },
  {
    rank: 4,
    name: "Emma",
    points: 808989,
    avatarUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
  },
  {
    rank: 5,
    name: "Mikeasdadadsas",
    points: 75898,
    avatarUrl:
      "https://images.pexels.com/photos/356079/pexels-photo-356079.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280",
  },
];

export const sampleUserNames = [
"Sketch Sherlock",
"Doodle Detective",
"Artful Agent",
"Pencil Prowler",
"Sleuth Sketcher",
"Clue Crafter",
"Inspector Ink",
"Drawing Detective",
"Mystery Marker",
"Gumshoe Graffiti",
"Secret Scribbler",
"Cipher Canvas",
"Clue Crayon",
"Detective Dabbler",
"InkyInvestigator",
"Puzzle Pencil",
"Hidden Hues",
"Mysterious Masterpiece",
"Sketch Sleuth",
"Artful Alias",
"Shadow Sketcher",
"Enigma Easel",
"Clue Brush",
"Mystery Muralist",
"Trace Tracker",
"Caper Canvas",
"Doodle Detective Dame",
"Case Closed Cartoonist",
"Case Cracker",
"Spy Glass",
"Fedora Fiddler",
"Laughing Lens"
];