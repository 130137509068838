import { ReactNode } from "react";
import { isUserInitialised, useAppSelector } from "../store/store";
import { Navigate, useLocation } from "react-router-dom";

interface RequireProfileDataProps {
  children: ReactNode;
}

const RequireProfileData: React.FC<RequireProfileDataProps> = ({children}) => {
    const isUserInit = useAppSelector(isUserInitialised);
    const location = useLocation();

    return isUserInit ? (
      <>{children}</>
    ) : (
      <Navigate to="/" replace state={{ path: location.pathname + location.search, fullPath: isUserInit }} />
    );
};

export default RequireProfileData;
