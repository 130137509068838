import DrawSegment from "../../../../domain/entities/DrawSegment";
import LineSegment from "../../../../domain/entities/LineSegment";


// Define batch parameters
const BATCH_SIZE = 15; // Number of line segments after which to send data
const TIME_THRESHOLD = 300; // Time in milliseconds to wait before sending data
const THIN_DISTANCE_THRESHOLD = 1; // points closer than this much sqr pixel distances are merged into one line segment.

let batchedLineSegments: DrawSegment[] = [];
let debounceTimer: NodeJS.Timeout | null = null;

/**
 * batching mechanism that accumulates line segments and 
 * uses a combination of size threshold and debouncing to 
 * determine when to send  the batched data 
 * by calling the passed socket emitter handler.
 */

export function addToBatch(lineSegment: DrawSegment, handler: Function) {
    batchedLineSegments.push(lineSegment);
    batchedLineSegments = thinDownDrawSegments(batchedLineSegments, THIN_DISTANCE_THRESHOLD);

    // Check if batch size is reached
    if (batchedLineSegments.length >= BATCH_SIZE) {
        sendBatchedData(handler);
        return;
    }

    // Debounce sending data
    if (debounceTimer) clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
        sendBatchedData(handler);
    }, TIME_THRESHOLD);
}

function sendBatchedData(handler: Function) {
    if (batchedLineSegments.length > 0) {
        handler(batchedLineSegments);
        batchedLineSegments = []; // Reset the batch
    }
    if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
    }
}

function thinDownDrawSegments(drawSegments: DrawSegment[], epsilon: number): DrawSegment[] {
    const thinnedSegments: DrawSegment[] = [];

    for (let i = 0; i < drawSegments.length; i++) {
        const currentDrawSegment = drawSegments[i];
        const { cl, th, dId, sId, pt, uAt } = currentDrawSegment;
        const currentLineSegment = currentDrawSegment.seg[0];
        let mergedSegment = new LineSegment(currentLineSegment.i, currentLineSegment.f);

        for (let j = i + 1; j < drawSegments.length; j++) {
            const nextDrawSegment = drawSegments[j];
            const nextLineSegment = nextDrawSegment.seg[0];

            const distance = Math.sqrt(
                Math.pow(nextLineSegment.i.x - mergedSegment.i.x, 2) +
                Math.pow(nextLineSegment.i.y - mergedSegment.i.y, 2)
            );

            if (distance <= epsilon) {
                mergedSegment = new LineSegment(mergedSegment.i, nextLineSegment.f);
                i++; // Skip the next draw segment as it's merged with the current one
            } else {
                break;
            }
        }

        const thinnedDrawSegment = new DrawSegment(cl, th, pt, dId, [mergedSegment], sId, uAt);
        thinnedSegments.push(thinnedDrawSegment);
    }

    return thinnedSegments;
}
