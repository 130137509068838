import { Button, Label, Modal, TextInput } from 'flowbite-react';
import React, { useState } from 'react';
import { MdEdit } from "react-icons/md";
import { setUserName, useAppDispatch } from '../../store/store';

const EditableText: React.FC<{ initialName: string }> = ({ initialName }) => {
    const dispatch = useAppDispatch();
  const [name, setName] = useState(initialName);
  const [tempName, setTempName] = useState(initialName);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempName(event.target.value);
  };

  const handleCheck = () => {
    if (tempName.trim() === '') {
      setError('Name?🙏');
      return;
    }
    setName(tempName);
    setIsEditing(false);
    setError('');
    // Dispatched name when changed in Redux, then is listened for change in Room.tsx (where avatar and userName updated to others).
    dispatch(setUserName(tempName));
  };

  const handleCancel = () => {
    setTempName(name);
    setIsEditing(false);
    setError('');
  };

  const renderModalToEditName = ():JSX.Element => {
    return (<>
    <Modal show={isEditing} size="md" onClose={handleCancel} popup className='text-gray-900' dismissible>
      <Modal.Header />
      <Modal.Body>
        <div className="space-y-6">
          <h3 className="text-xl font-medium text-gray-900 dark:text-white">Don't like your name?</h3>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="userName" color={error?"failure":""}value="Your new name:" />
            </div>
            <TextInput
              id="userName"
              value={tempName}
              onChange={handleChange}
              required
              color={error?"failure":""}
            />
            {error ? <p className=' font-bold text-red-900 bg-red-500 border-2 rounded-xl border-red-700 w-3/4 px-1 text-center mx-auto'>{error}</p>:null}
          </div>
          <div className="w-full">
            <Button onClick={handleCheck}>Set Name</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </>);
  }

  return (
    <div className="relative w-32">
        <div onClick={handleClick} className="mx-auto flex flex-row w-[120px] justify-between gap-2 place-items-center cursor-pointer border-[1px] rounded-xl border-slate-900/30 px-2 hover:border-slate-900/80 hover:bg-slate-900/20">
          <div className='flex-1 truncate text-xs'>{name}</div> <div className='w-fit'><MdEdit/></div>
        </div>
        {/* Modal: */}
        {renderModalToEditName()}
    </div>
  );
};

export default EditableText;