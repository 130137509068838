import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { ClientGameData, ClientTurnData, GifGameData, idMapType } from "../../../../../common/types";
import DrawSegment from "../../../../../domain/entities/DrawSegment";
import Chat from "../../../../../domain/entities/Chat";
import SoundManager from "../../common/SoundManager";
import { PaintType } from "../../../../../common/Constants";
import Game from "../../../../../domain/entities/Game";
import { TurnSummaryObject, wordDataUpdateObject } from "../../../../../domain/entities/DataUpdates";

const initialState = {
  gameData: null as ClientGameData | null,
  canvasDrawData: {
    // For State design logic, refer: https://github.com/ranjan-malav/pictoparty/issues/6
    toDrawQueue: [] as DrawSegment[],
    canvasCleanUpRequired: false,
  },
  chatMessagesList: [] as Chat[],
  roomId: null as string | null,
  userCanvasPrefs: {
    hexColor: '#000000',
    lineWidth: 5,
    eraserOrBrushSelected: PaintType.BRUSH,
  },
  gifData: {
    toDraw: null as GifGameData | null,
    imgData: null as string | null,
    idMap: {} as idMapType,
    gifGameData: null as Game | null,
  },
  turnSummaryData: null as TurnSummaryObject | null,
};

export const gameDataSlice = createSlice({
  name: "gameData",
  initialState,
  reducers: {
    // 1️⃣ gameData Section:
    replaceGameDataTotally: (state, action: PayloadAction<ClientGameData>) => {
      // todo: Validation?
      state.gameData = action.payload;
    },
    updateGameData: (state, action: PayloadAction<ClientGameData>) => {
      // Update only the keys present
      state.gameData = { ...state.gameData, ...action.payload };
    },
    setWordUpdate: (state, action: PayloadAction<wordDataUpdateObject>) => {
      if(state.gameData?.turnData){
        state.gameData.turnData.word = action.payload.data;
        console.log("Updating word by hint: ", action.payload.data);
      }
    },

    // 2️⃣ Canvas Draw Data Section:
    addEdgesToDrawQueue: (state, action: PayloadAction<DrawSegment[]>) => {
      state.canvasDrawData.toDrawQueue.push(...action.payload);
    },
    resetToDrawQueueOnly: (state) => {
      state.canvasDrawData.toDrawQueue = [];
    },
    setCanvasCleanUpRequiredOnly: (state, action: PayloadAction<boolean>) => {
      state.canvasDrawData.canvasCleanUpRequired = action.payload;
    },
    requestRepaintAndResetToDrawQueueAndAndAddTheseEdges: (
      state,
      action: PayloadAction<DrawSegment[]>
    ) => {
      state.canvasDrawData.canvasCleanUpRequired = true;
      state.canvasDrawData.toDrawQueue = action.payload;
    },

    // 3️⃣ Chat Messages Section:
    addMessagesToMessageList: (state, action: PayloadAction<Chat[]>) => {
      // todo: Validation on messages? (Not empty, vulgar, Sensitive(guessed-word))
      // Remove duplicate messages, in case server sends data again
      // Combine existing chats with new chats
      let uniqueNewChats = action.payload.filter(
        (newChat) =>
          !state.chatMessagesList.some(
            (existingChat) => existingChat.uid === newChat.uid
          )
      );
      // Playing sound depending on the chat message type.
      // Only playing if a single chat has come from the server, not to play multiple sound at once.
      if (uniqueNewChats.length == 1) {
        SoundManager.playSound(uniqueNewChats[0]);
      }
      state.chatMessagesList.push(...uniqueNewChats);
    },
    replaceMessagesListTotally: (state, action: PayloadAction<Chat[]>) => {
      // todo: Validation?
      state.chatMessagesList = action.payload;
    },

    // 4️⃣ Room section:
    setRoomId: (state, action: PayloadAction<string | null>) => {
      // todo: Validation?
      state.roomId = action.payload;
    },

    // 5️⃣ Canvas Prefs: (Color, thickness, selected tool).
    setUserCanvasPrefs: (state, action: PayloadAction<{ hexColor?: string, lineWidth?: number, eraserOrBrushSelected?: PaintType }>) => {
      state.userCanvasPrefs = { ...state.userCanvasPrefs, ...action.payload };
    },
    toggleBrushOrEraserPref: (state) => {
      state.userCanvasPrefs.eraserOrBrushSelected = state.userCanvasPrefs.eraserOrBrushSelected === PaintType.BRUSH ? PaintType.ERASER : PaintType.BRUSH;
    },

    // gifData:
    setGifImgData: (state, action: PayloadAction<string | null>) => {
      state.gifData.imgData = action.payload;
    },
    setGifToDrawData: (state, action: PayloadAction<GifGameData | null>) => {
      state.gifData.toDraw = action.payload;
    },
    setGifIdMapData: (state, action: PayloadAction<idMapType>) => {
      state.gifData.idMap = action.payload;
    },
    setGifGameData: (state, action: PayloadAction<Game>) => {
      state.gifData.gifGameData = action.payload;
    },

    // 6️⃣ TurnSummary data:
    setTurnSummaryData: (state, action: PayloadAction<TurnSummaryObject | null>) => {
      state.turnSummaryData = action.payload;
    },

    // Misc:
    resetGameDataSlice: () => {
      return initialState;
    },
  },
});

export const {
  // Game Data
  replaceGameDataTotally, updateGameData,
  // Canvas
  addEdgesToDrawQueue, setCanvasCleanUpRequiredOnly, resetToDrawQueueOnly, requestRepaintAndResetToDrawQueueAndAndAddTheseEdges,
  // Messages
  addMessagesToMessageList, replaceMessagesListTotally,
  // Room
  setRoomId,
  // UserCanvasPrefs
  setUserCanvasPrefs, toggleBrushOrEraserPref,
  //gifData
  setGifImgData, setGifToDrawData, setGifIdMapData, setGifGameData,
  // turnSummary:
  setTurnSummaryData,
  // Misc:
  resetGameDataSlice,
  setWordUpdate,
} = gameDataSlice.actions;

// Game Data
export const getGameSettings = (state: RootState) => state.gameDataSlice.gameData?.settings;
export const getTimer = (state: RootState) => state.gameDataSlice.gameData?.turnData?.timer;
export const getDuration = (state: RootState) => state.gameDataSlice.gameData?.turnData?.totalDuration;
export const getGameData = (state: RootState) => state.gameDataSlice.gameData;
export const getWord = (state: RootState) => state.gameDataSlice.gameData?.turnData?.word;
export const getDrawerId = (state: RootState) => state.gameDataSlice.gameData?.turnData?.drawerId;
export const getLastTurn = (state: RootState): ClientTurnData | undefined => state.gameDataSlice.gameData?.turnData;
export const getLastRoundLastTurnStatus = (state: RootState) => {
  const lastTurn = getLastTurn(state);
  return lastTurn ? lastTurn.status : null;
}
export const getGameStatus = (state: RootState) => state.gameDataSlice.gameData?.status;
export const getDrawerIdOfLastRoundAndLastTurn = (state: RootState): string | null => {
  const lastTurn = getLastTurn(state);
  return lastTurn ? lastTurn.drawerId : null;
}
export const getPlayerScores = (state: RootState) => state.gameDataSlice.gameData?.scores;

// Canvas
export const getToDrawQueueData = (state: RootState) =>
  state.gameDataSlice.canvasDrawData.toDrawQueue;
export const isCanvasCleanUpRequired = (state: RootState) =>
  state.gameDataSlice.canvasDrawData.canvasCleanUpRequired;

// Messages
export const getAllMessages = (state: RootState) => state.gameDataSlice.chatMessagesList;

// Room
export const getRoomId = (state: RootState) => state.gameDataSlice.roomId;

// UserCanvasPrefs:
export const getUserCanvasPrefs = (state: RootState) => state.gameDataSlice.userCanvasPrefs;

// gifData:
export const getGifData = (state: RootState) => state.gameDataSlice.gifData;
export const getGifGameData = (state: RootState) => state.gameDataSlice.gifData.gifGameData;

// Turn Summary:
export const getTurnSummaryData = (state: RootState) => state.gameDataSlice.turnSummaryData;

export default gameDataSlice.reducer;
