import { ALPHABET_REGEX, MASKED_CHARACTER_SYMBOL, SPACED_CHARACTER_SYMBOL } from "../../common/Constants";
import DrawSegment from "../entities/DrawSegment";

export const last = <T>(array: T[]): T => {
    if (!array.length) {
        return undefined
    }
    return array[array.length - 1];
}

export const getRandomCount = (min: number, max: number): number => {
    // Ensure min <= max
    if (min > max) {
        [min, max] = [max, min];
    }
    // Generate random value between 0 and 1
    const randomValue = Math.random();
    // Calculate the range within desired count
    const range = max - min + 1;
    // Scale the random value to the count range
    const randomCount = Math.floor(randomValue * range) + min;
    return randomCount;
}

export const getRandomString = (array: string[]): string => array[getRandomCount(0, array.length - 1)]

export const getDrawerIdFromDrawSegments = (drawSegments: DrawSegment[]): string => {
    if (!drawSegments || drawSegments.length == 0) {
        return '';
    }
    return drawSegments[0].dId;
};

export function firstOrNull<T>(array: T[], predicate: (element: T) => boolean): T | null {
    for (let element of array) {
        if (predicate(element)) {
            return element;
        }
    }
    return null;
}

export function replaceCharAtIndex(str, index, char): string {
    try {
        return str.substring(0, index) + char + str.substring(index + 1);
    } catch (error) {
        console.log(`Index out of bounds string: ${str}, index: ${index}, cahr: ${char}`, error);
        return str;
    }
}

export function maskedAndRevealableString(input: string, isRevealing: boolean = false): string{
    return isRevealing ? 
        // If alphabet, then keep alphabet (as revealed),
        // If space then keep space.
        input.split('').map((ch) => ALPHABET_REGEX.test(ch) || ch===SPACED_CHARACTER_SYMBOL ? ch: MASKED_CHARACTER_SYMBOL).join('')
        :
        // every alphabet is _ ,
        // every space cahracter is retained as space,
        input.split('').map((ch) => ALPHABET_REGEX.test(ch) ? MASKED_CHARACTER_SYMBOL: ch ).join('');
}