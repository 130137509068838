import * as packageJson from "../package.json";

// Access the version property
const version = packageJson.version;

export const printConsoleMessage = () => {
    console.table({
        'App Version': version,
        'OS': navigator['platform'],
        'Browser': navigator['appCodeName'],
        'Language': navigator['language'],
    });
}