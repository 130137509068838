// ToggleInput.tsx
import { Tooltip } from "flowbite-react";
import React from "react";
import { MdInfoOutline } from "react-icons/md";

interface ToggleInputProps {
  label: string;
  name: string;
  value: boolean;
  onChange: (name: string, value: number | boolean) => void;
  readOnly?: boolean;
  paragraphText: string;
}

const ToggleInput: React.FC<ToggleInputProps> = ({
  label,
  name,
  value,
  onChange,
  paragraphText,
  readOnly=false
}) => {
  const handleToggle = () => {
    if(readOnly) return;
    onChange(name, !value); // Pass name along with the new value
  };

  return (
    <div>
      <label className={`flex items-center ${readOnly? "cursor-not-allowed":"cursor-pointer"} justify-between`}>
        <input
          type="checkbox"
          value=""
          className="sr-only peer"
          checked={value}
          onChange={handleToggle}
          disabled={readOnly}
        />
        <div className="flex gap-2 place-items-center mr-2 text-xs font-medium text-gray-950">
          <div>{label}</div>
          <Tooltip content={paragraphText} placement="top" className="text-gray-100"><MdInfoOutline/></Tooltip>
        </div>
        <div className={`relative w-11 h-6 bg-gray-400 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}></div>
      </label>
    </div>
  );
};

export default ToggleInput;
