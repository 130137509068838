import React, { useState } from "react";

interface MyAvatarProps {
  avatarId: number;
}

const MyAvatar: React.FC<MyAvatarProps> = ({ avatarId }) => {
  const [imageError, setImageError] = useState<boolean>(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div>
      {imageError ? (
        // Fallback image if the avatar image fails to load
        <img
          src="0.svg"
          className="max-h-16 max-w-16"
          alt={`Fallback Avatar`}
        />
      ) : (
        // Avatar image
        <img
          src={`${avatarId}.svg`}
          className="max-h-16 max-w-16"
          alt={`Avatar ${avatarId}`}
          onError={handleImageError}
        />
      )}
    </div>
  );
};

export default MyAvatar;
