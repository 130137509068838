import Chat from "../../../../domain/entities/Chat";
import { getChatAudio } from "../components/chat/ChatHistory";

class SoundManager {
    static audio = new Audio();

    static playSound = (chat: Chat) => {
        // const audio = getChatAudio(chat)
        // this.audio.src = audio;
        // this.audio.play();
    };
}

export default SoundManager;