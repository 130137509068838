import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { getUserAvatarId, getUserName, requestRepaintAndResetToDrawQueueAndAndAddTheseEdges, setTurnSummaryData, useAppDispatch, useAppSelector } from '../store/store';
import { getAllUsers, getUserId } from '../store/store';
import { isGameNotRunning, useAvatarSelection, useGetFromQueryParam } from '../common/utils';
import { useGetRoomInfoQuery } from '../store/apis/pictoPartyApi';
import LoadingSpinner from './loadingSpinner/LoadingSpinner';
import { joinRoom, refreshAndReconnectSocket, updateUserNameOrAvatar } from '../socket';
import Game from './Game';
import { getGameData } from '../store/store';
import { GameStatus, MAX_AVATAR_ICONS_DEFINED } from '../../../../common/Constants';
import { setRoomId } from '../store/store';
import { ClientGameData } from '../../../../common/types';
import MyAvatar from './ui/MyAvatar';
import { renderConnectiontatus } from './UserListSection';
import EditableText from './ui/EditableText';
import { IoRefreshCircleSharp } from 'react-icons/io5';
import WaitingLobbyScreen, { LandingTab } from './ui/LobbyMenu';
import { Badge } from 'flowbite-react';


function isUserAnAdmin(userId: string | null, clientGameData: ClientGameData | null) {
    return !!userId && !!clientGameData?.adminIds?.includes(userId);
}

function RoomPage() {
    const roomId = useGetFromQueryParam('roomId');
    const fetchedRoomInfo = useGetRoomInfoQuery(roomId);
    const userId = useAppSelector(getUserId);
    const reduxGameData = useAppSelector(getGameData);
    const userList = useAppSelector(getAllUsers);
    const dispatch = useAppDispatch();
    const userAvatarId = useAppSelector(getUserAvatarId) || Math.floor(Math.random() * MAX_AVATAR_ICONS_DEFINED) + 1;
    const userName = useAppSelector(getUserName);
    const { avatarId, handleChangeAvatar } = useAvatarSelection(
      MAX_AVATAR_ICONS_DEFINED,
      userAvatarId
    );

    useEffect(()=>{
      updateUserNameOrAvatar({userId: userId||'', avatarId: userAvatarId, userName: userName || 'Anon.' });
    // 🤜 todo: bug: update sent, but not updated for other players in lobby, and gets reverted in gameplay or leaderboard.
    }, [userAvatarId, userName]);

    useEffect(() => {
        // AppSocket.initSocket();
        dispatch(setRoomId(roomId));
    }, [roomId]);

    // Only on successful room info fetch, send JOIN_ROOM event
    useEffect(() => {
      if (
        !fetchedRoomInfo.isLoading &&
        !fetchedRoomInfo.isError &&
        fetchedRoomInfo.isSuccess
      ) {
        refreshAndReconnectSocket(()=>joinRoom());
      }
    }, [
      fetchedRoomInfo.data,
      fetchedRoomInfo.isLoading,
      fetchedRoomInfo.isError,
      fetchedRoomInfo.isSuccess,
    ]);
    const reduxDataGameStatus = reduxGameData?.status;
    
    // Post Game Cleanups:
    //  - Clean up any local redux or canvas related states. Not pushed to server (local only).
    //  - Ensures that when next game started, then it starts clean.
    useEffect(() => {
      if(reduxDataGameStatus !== GameStatus.FINISHED){
        return;
      }
      // clean up canvas (as if this is an earlier drawer, then HTML canvas still retains the drawing data).
      dispatch(requestRepaintAndResetToDrawQueueAndAndAddTheseEdges([]));

      // cleanup lastTurnSummaryData data in redux too:
      dispatch(setTurnSummaryData(null));
    }, [reduxDataGameStatus]);

    const isUserAdmin = isUserAnAdmin(userId, reduxGameData);

    const renderJoinedUsers = () => {
      return (
        <>
          {userList.map((player) => (
            <div
              key={player.uid}
              className="relative border-[3px] shadow-xl border-gray-600/80 hover:border-gray-800/80 rounded-md m-1 p-1 w-32 md:w-[196px] flex flex-col md:flex-row place-items-center justify-between bg-blue-300/70 hover:bg-blue-500/70"
            >
              {/* 1️⃣ Avatar */}
              <div className='relative h-12 w-12 mt-2 md:mt-4 md:mb-2'>
              {player.uid !== userId ?
                <MyAvatar avatarId={player.avatarId} /> :
                <>
                  <MyAvatar avatarId={avatarId} />
                  <button
                    onClick={handleChangeAvatar}
                    className="bg-black hover:text-gray-400 text-white rounded-full absolute -bottom-2 -right-2"
                  >
                    <IoRefreshCircleSharp className="w-6 h-6" />
                  </button>
                </>}
              </div>

              {/* 2️⃣ User Name */}
              {player.uid === userId ? <EditableText initialName={player.userName}/> : <div className='w-[120px] truncate text-xs mt-1 text-center'>{player.userName}</div>}

              {/* 3️⃣ Badges: Admin, You, Network Disconnected */}
              <div className='absolute top-0 right-0 mt-0 mr-0'>
                {player.uid === userId && (<Badge
                  color="info"
                  size="xs"
                  className='opacity-80'
                >
                  You
                </Badge>
                )}
              </div>
              <div className='absolute top-0 left-0 mt-0 ml-0'>
                {isUserAnAdmin(player.uid, reduxGameData) && (
                  <Badge
                  color="info"
                  size="xs"
                  className='opacity-80 w-fit'
                >Admin
                </Badge>
                )}
              </div>
              <div className='absolute bottom-0 right-0 mb-0 mr-0'>
                {renderConnectiontatus(player)}
              </div>
            </div>
          ))}
        </>
      );
    };

    /* 👉todo: On player leaving game from lobby screen, currently others are not updated. */

    const renderContent = () => {
        if (!roomId) {
            return <p>Pls mention Room ID in URL.</p>
        } else if (fetchedRoomInfo.isFetching) {
            return <LoadingSpinner />;
        } else if (fetchedRoomInfo.isError) {
          return (
            <div>
              <pre>{JSON.stringify(fetchedRoomInfo.error, null, 2)}</pre>
              {toast.error(JSON.stringify(fetchedRoomInfo.error, null, 2))}
            </div>
          );
        }
        //
        else if (!fetchedRoomInfo.data) {
          return <p>Room data emtpy!</p>;
        } else if (!reduxGameData) {
          return <LoadingSpinner/>;
        } else if (reduxGameData) {
          // 👉 Check if user not part of player list in room. Seems buggy.
          if(!userList.find(u=> u.uid === userId)){
            return <p>You are not in User list anymore. Either left the game or got kicked out.</p>
          }
          const gameStatus = reduxGameData.status;
          if (
            (reduxGameData && reduxGameData.status === GameStatus.FINISHED) ||
            gameStatus === GameStatus.FINISHED
          ) {
            return (
              <WaitingLobbyScreen 
                leftPanelContent={renderJoinedUsers()}
                readOnly = {!isUserAdmin}
                landingTab = {LandingTab.LEADERBOARD}
                userList={userList}
                gameStatus = {gameStatus}
              />
            );
          }
          if (
            (reduxGameData && isGameNotRunning(reduxGameData.status)) ||
            (!reduxGameData && isGameNotRunning(gameStatus))
          ) {
            return (
                <WaitingLobbyScreen
                  leftPanelContent={renderJoinedUsers()}
                  readOnly = {!isUserAdmin}
                  landingTab = {LandingTab.SETTINGS}
                  userList={userList}
                  gameStatus = {gameStatus}
                />
            );
          }
          if (reduxGameData && reduxGameData.status === GameStatus.RUNNING) {
            return <Game />;
          }

          return (
            <p>{`Game not running [${gameStatus}]`}</p>
          );
        }
        return <>❗ No idea what to render</>;
    }


    return <div>{renderContent()}</div>;
}

export default RoomPage;