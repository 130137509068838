import React from "react";
import { Modal as FlowbiteModal } from "flowbite-react";

interface ModalProps {
  isOpen: boolean;
  setOpenModal: (isOpen: boolean) => void;
  headerContent: React.ReactNode;
  bodyContent: React.ReactNode;
  footerContent: React.ReactNode;
  dismissable?: boolean;
}

const MyModal: React.FC<ModalProps> = ({
  isOpen,
  setOpenModal,
  headerContent,
  bodyContent,
  footerContent,
  dismissable = false,
}) => {
  return (
    <FlowbiteModal
      dismissible={dismissable}
      show={isOpen}
      onClose={() => setOpenModal(false)}
    >
      <FlowbiteModal.Header>{headerContent}</FlowbiteModal.Header>
      <FlowbiteModal.Body>{bodyContent}</FlowbiteModal.Body>
      <FlowbiteModal.Footer>{footerContent}</FlowbiteModal.Footer>
    </FlowbiteModal>
  );
};

export default MyModal;