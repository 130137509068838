import Canvas, { canvasStyle } from './Canvas';
import { getDrawerId, getTurnSummaryData, useAppSelector } from '../../store/store';
import Lottie from "lottie-react";

import { getUserId } from '../../store/store';
import { startTurn } from '../../socket';
import { getGameData } from '../../store/store';
import WordChoose from './WordChoose';
import { CANVAS_HEIGHT_PX, CANVAS_WIDTH_PX, TurnStatus } from '../../../../../common/Constants';
import TimerBar from '../TimerBar';
import lottieWaitingHourGlass from "./WaitingHourGlass.json";
import lottieTurnEndSummary from "../../lotties/turn-end-view-summary.json";
import lottiePaintBrush from "./PaintBrush.json";
import lottieLoadingColorsBouncing from "../../lotties/loading_bouncing_colors.json";
import { getUserFromUserId } from '../../common/utils';
import MyAvatar from '../ui/MyAvatar';
import { Scores } from '../../../../../common/types';

function CanvasSection() {
  const userId = useAppSelector(getUserId);
  const gameData = useAppSelector(getGameData);
  const words = gameData?.turnData?.wordSuggestions;
  const drawerId = useAppSelector(getDrawerId);
  const lastTurnSummary = useAppSelector(getTurnSummaryData);
  const isLastRound = !!(gameData?.currentRound && gameData?.currentRound === gameData?.totalRounds);

  return (
    <div className="Canvas text-slate-800 flex-1 flex flex-row">
      {/* 
        1️⃣ Either Show Buffer / Prep Screen */}
      {gameData?.turnData?.status === TurnStatus.CREATED  ? 
      renderTurnStartView()
      /*Or choosing word as a drawer (and showing guessers that drawer is choosing word)*/
        // 2️⃣ Woord Choose
      : gameData?.turnData?.status === TurnStatus.COMPLETED ?
      renderTurnEndView()
      :
      gameData?.turnData?.status === TurnStatus.PREPPED ? (
        renderWordChoose(words)
      ) : // 3️⃣ Canvas
      // Or seeing Canvas (where wither drawing or seeing another drawer draw) 
      userId ? (
        <Canvas width={CANVAS_WIDTH_PX} height={CANVAS_HEIGHT_PX} userId={userId} />
      ) : (
        // 4️⃣ Not sure if this will ever happen.
        <>
          <p>Not sure what to show. Neither Waiting, nor choosing word nor drawing.</p>
        </>
      )}
      <div><TimerBar/></div>
    </div>
  );

  function renderWordChoose(words: string[] | undefined) {
    // Drawer will see word choice screen
    // Guesser will see that drawer is choosing word
    const isDrawer = !!(words && words.length);
    
    return (
        <div className='flex flex-col place-items-center justify-start bg-green-200' style={canvasStyle}>
          <Lottie loop animationData={isDrawer ? lottiePaintBrush : lottieLoadingColorsBouncing} className='h-40 w-40 mx-auto' />
          <WordChoose
            words={words}
            onItemClick={(i) => {
              if(words && words.length){
                startTurn(words[i]);
              }
            } } 
            isDrawer={isDrawer}/>
        </div>
    );
  }

  function renderTurnStartView() {
    const drawerName = !drawerId? "?" : getUserFromUserId(drawerId)?.userName || "?";

    return <div className='flex flex-col place-items-center justify-start bg-yellow-200' style={canvasStyle}>
      <Lottie loop animationData={lottieWaitingHourGlass} className='h-20 w-20 mx-auto' />
      <div className='mx-auto text-center'>
        {<div className='text-lg' style={{animationDuration: '.75s'}}>Ready!</div>}
        {isLastRound ? <div className='motion-safe:animate-pulse font-bold text-lg text-red-700' style={{animationDuration: '.75s'}}>Last round, make it count!</div> : null}
        {(userId === drawerId ? <div className=''><span className='text-orange-600 font-bold'>Your</span> turn to draw next!</div> : <div className=''><span className='font-bold text-green-700'>{drawerName}</span> is the next drawer!</div>)}
      </div>
    </div>;
  }

  function renderTurnEndView(){
    return <div className='flex flex-col place-items-center justify-start bg-yellow-200' style={canvasStyle}>
      <Lottie loop animationData={lottieTurnEndSummary} className='h-20 w-20 mx-auto' />
      {/* Todo: Adjust to avatar name and id from Players slice. */}
      {lastTurnSummary? (<div className='mx-auto mt-2'>
          <p className='text-center font-bold'>Last turn summary:</p>
          <p className='text-center'>Word was <b>{lastTurnSummary?.data.word}</b></p>
          {lastTurnSummary?.data.everyoneGuessed ? <p className='text-center'>✅ Everyone guessed</p>: null}
          {lastTurnSummary?.data.scores && Object.keys(lastTurnSummary?.data.scores).length ? renderScoreTable(lastTurnSummary?.data.scores) : null}
        </div>)
        : null
      }
    </div>;
  }

  function renderScoreTable(scores: Scores | undefined | null) : JSX.Element | null {
    if(!scores || !Object.keys(scores).length) return null;
    return (
      <div className='max-h-24 sm:max-h-[450px] lg:max-h-[500px] overflow-auto flex flex-col gap-1 my-2 py-1 border-y-2 border-gray-600 dark:border-gray-400'>
        {Object.keys(scores).map(uid => {
            const user = getUserFromUserId(uid);
            
            return (user && <div className='flex gap-2 justify-between divide-y-reverse' key={uid}>
              <div className='flex gap-1'>
                <div className='w-6 h-6'><MyAvatar avatarId={user.avatarId}/></div>
                <div className='font-bold'>{user.userName}</div>
              </div>
              <div className=''>
                + {scores[uid]}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default CanvasSection;