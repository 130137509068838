import { RxLapTimer } from "react-icons/rx";
import useTimer from "../common/useTimer";

/**
 * Whenever DrawerId changes or timeDuration changes, just restart the timer (of 10 sec).
 */
const Timer = () => {
    const {seconds, duration} = useTimer();

    return (
      <div className="flex items-center justify-center gap-1 px-1 mx-1 rounded-lg">
        <RxLapTimer className="h-3 w-3 md:h-5 md:w-5"/> <p> <span className="text-xs font-bold">{seconds}</span>s<br/>/<span className="text-xs">{duration}</span></p>
      </div>
    );
};

export default Timer;