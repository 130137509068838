import Point from "./Point";


/**
 * To reduce the data transfer, we abbreviated these keys
 * initial - i
 * final - f
 */
class LineSegment {
    i: Point;
    f: Point;

    constructor(initial: Point, final: Point) {
        this.i = initial;
        this.f = final;
    }
}

export default LineSegment;