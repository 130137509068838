import { BsFillTrophyFill } from "react-icons/bs";
import { getUserId, useAppSelector } from "../store/store";
import { getPlayerScores } from "../store/store";
import { getPlayerRankings } from "../common/utils";
import { getAllUsers } from "../store/store";
import MyAvatar from "./ui/MyAvatar";
import { UNKNOWN_IMG_ID } from "../../../../common/Constants";
import { Badge } from "flowbite-react";
import BuyMeACoffeeButton from "./BuyMeCoffeeButton";

interface LeaderboardData {
  isPlayer: boolean;
  rank: number;
  name: string;
  points: number;
  avatarId: number;
}

const LeaderboardCard: React.FC<LeaderboardData> = ({ isPlayer, rank, name, points, avatarId }) => {
  return (
    <div className="relative max-w-sm bg-yellow-300/95 hover:bg-yellow-400/80 text-gray-900 shadow-xl border-[3px] border-yellow-800/60 hover:border-yellow-900/70 rounded-lg p-4 flex place-items-center justify-between">
      <div className="flex place-items-center">
        <div className="flex flex-row max-w-[90px] place-items-center justify-between">
          <div className="text-sm font-bold">{rank ? "#" + rank : "?"}</div>
          <div className="h-10 w-10 mx-1">
            <MyAvatar avatarId={avatarId} />
          </div>
        </div>
        <div className="font-normal text-sm whitespace-normal break-all w-[95px]">{name}</div>
        {isPlayer && (
          <Badge
            color="info"
            size="xs"
            className="opacity-80 w-fit absolute top-0 right-0 mt-[2px] mr-[2px]"
          >
            You
          </Badge>
        )}
      </div>
      <div className="flex items-center">
        {rank <= 3 && <BsFillTrophyFill color="red" />}
        <span className="ml-4 text-gray-600 max-w-[55px] truncate text-right text-sm">{points}</span>
      </div>
    </div>
  );
};

const Leaderboard: React.FC = () => {
  const playerScores = useAppSelector(getPlayerScores);
  const allPlayers = useAppSelector(getAllUsers);
  const playerRankings = getPlayerRankings(playerScores);
  const userId = useAppSelector(getUserId);

  const leaderboardData: LeaderboardData[] = allPlayers
    .map((player) => {
      return {
        isPlayer: player.uid === userId,
        rank: playerRankings[player.uid],
        name: allPlayers.find((p) => p.uid === player.uid)?.userName || "Anon.",
        points:
          playerScores && playerScores[player.uid]
            ? playerScores[player.uid]
            : 0,
        avatarId:
          allPlayers.find((p) => p.uid === player.uid)?.avatarId ||
          UNKNOWN_IMG_ID,
      };
    }).sort((a, b) => b.points - a.points);

  return (
    <div className="container max-w-sm mx-auto">
      <BuyMeACoffeeButton />
      <div className="grid gap-4 justify-center">
        {leaderboardData.map((entry, index) => (
          <LeaderboardCard
            key={index}
            isPlayer={entry.isPlayer}
            rank={entry.rank}
            name={entry.name}
            points={entry.points}
            avatarId={entry.avatarId}
          />
        ))}
      </div>
    </div>
  );
};

export default Leaderboard;