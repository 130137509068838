import React, { useState } from "react";
import MyButton from "./Button";
import MyModal from "./MyModal";

const MyPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <h1>My MyPage</h1>
      <button onClick={openModal}>Open Modal</button>
      <MyModal
        isOpen={isModalOpen}
        setOpenModal={setIsModalOpen}
        headerContent={<p>Custom Header Content</p>}
        bodyContent={<p className="text-base text-slate-700">Custom Body Content</p>}
        footerContent={
          <>
            <MyButton primary rounded onClick={closeModal}>Close</MyButton>
            <MyButton primary rounded onClick={() => console.log("Custom action")}>
              Custom Button
            </MyButton>
          </>
        }
      />
    </div>
  );
};

export default MyPage;