export function getRoomIdFromUrlParams(){
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("roomId");
}

export const USER_ID_KEY = "userId";
export const USER_NAME_KEY = "user_name";
export const USER_AVATAR_ID= "avatar_id";

export const GIF_MESSAGE_POSITIONS = [[480, 170 ], [480, 320], [480, 470 ]];
export const GIF_GUESSERS_POSITIONS = [[480, 170 ], [480, 170 + 75], [480, 170 + (75*2) ], [480, 170 + (75*3) ], [480, 170 + (75*4) ], [480, 170 + (75*5) ]];