import { PayloadAction, createSlice, nanoid } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { USER_AVATAR_ID, USER_ID_KEY, USER_NAME_KEY } from "../../common/constants";
import { getFromLocalStorageByKey, isEmptyString, setLocalStorageByKey } from "../../common/utils";
import { UNKNOWN_IMG_ID } from "../../../../../common/Constants";
import { ClientPlayerData } from "../../../../../common/types";

type UserSliceState = {
  socketId: string | null,
  userId: string | null,
  userName: string | null,
  userAvatarId: number | null,
  userList: ClientPlayerData[],
};

const initialState: UserSliceState = {
  socketId: null,
  userId: getUserIdFromLocalStorageOrCreateNewAndStore(),
  userName: getFromLocalStorageByKey(USER_NAME_KEY),
  userAvatarId: parseInt(getFromLocalStorageByKey(USER_AVATAR_ID) || ""),
  userList: [],
};

export const userList = createSlice({
  name: "userList",
  initialState,
  reducers: {
    replaceUserListTotally: (state, action: PayloadAction<ClientPlayerData[]>) => {
      // todo: Validation?
      state.userList = action.payload;
    },
    resetUserList: (state) => {
      state.userList = [];
    },
    setUserSocketId: (state, action: PayloadAction<string>) => {
      state.socketId = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
      setLocalStorageByKey(USER_ID_KEY ,action.payload);
    },
    setUserName: (state, action: PayloadAction<string>)=> {
      // Empty names? Name check with other users?
      state.userName = action.payload;
      setLocalStorageByKey(USER_NAME_KEY, action.payload);
    },
    setUserAvatarId: (state, action: PayloadAction<number>)=> {
      state.userAvatarId = action.payload;
      setLocalStorageByKey(USER_AVATAR_ID, action.payload.toString());
    },
    resetUserSlice: () => {
      return initialState;
    }
  },
});

export const {
  replaceUserListTotally,
  resetUserList,
  setUserSocketId,
  setUserName,
  resetUserSlice,
  setUserAvatarId,
} = userList.actions;

export const getAllUsers = (state: RootState) => state.usersList.userList;
export const getSocketId = (state: RootState) => state.usersList.socketId;
export const getUserId = (state: RootState) => state.usersList.userId;
export const getUserName = (state: RootState) => state.usersList.userName;
export const getUserAvatarId = (state: RootState) => state.usersList.userAvatarId;
export const isUserInitialised = (state: RootState): boolean =>
  !!(
    !isEmptyString(state.usersList.userId) &&
    !isEmptyString(state.usersList.userName) &&
    state.usersList.userAvatarId &&
    state.usersList.userAvatarId > UNKNOWN_IMG_ID
  );

function getUserIdFromLocalStorageOrCreateNewAndStore() {
  const savedUserId = getFromLocalStorageByKey(USER_ID_KEY);
  if (!savedUserId) {
    const newUserId = nanoid();
    setLocalStorageByKey(USER_ID_KEY, newUserId);
    return newUserId;
  }
  return savedUserId;
}

export default userList.reducer;
