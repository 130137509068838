import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useGetLobbyInfoQuery } from '../store/apis/pictoPartyApi';
import { Card, Spinner, Tooltip } from 'flowbite-react';
import MyButton from './ui/Button';
import { FaUser } from 'react-icons/fa';
import { IoMdRefresh } from 'react-icons/io';

const PublicRoomInfoComponent: React.FC = () => {
    const fetchedLobbyInfo = useGetLobbyInfoQuery();
    const navigate = useNavigate();

    return (
        <div className='h-56 overflow-auto'>
            {fetchedLobbyInfo.isFetching && <Spinner className='mx-auto place-content-center' aria-label="Extra small spinner example" size="xl" />}
            {fetchedLobbyInfo.isError && (
                <div>
                    <pre>{JSON.stringify(fetchedLobbyInfo.error, null, 2)}</pre>
                    {toast.error(JSON.stringify(fetchedLobbyInfo.error, null, 2))}
                </div>
            )}
            <div className='flex flex-wrap gap-1'>
                {!fetchedLobbyInfo.isFetching && !fetchedLobbyInfo.isError && (
                    fetchedLobbyInfo.data?.map(lobby =>
                        <Card key={lobby.uuid} className="w-[150px] bg-slate-400 hover:bg-slate-300 hover:scale-95 hover:border-slate-900 hover:border-2">
                            <div className="text-sm font-bold tracking-tight text-gray-900 dark:text-white text-center">
                                {lobby.label}
                            </div>
                            <div className='flex justify-between gap-1 text-sm'>
                                <div className="font-normal text-gray-700 dark:text-gray-400">
                                <Tooltip content="Number of Players">
                                    <FaUser /> {lobby.noOfPlayers}
                                </Tooltip>
                                </div>
                                <div className="font-normal text-gray-700 dark:text-gray-400">
                                <Tooltip content="Number of Rounds">
                                    <IoMdRefresh /> {lobby.roundsPlayed}/{lobby.totalRounds}
                                </Tooltip>
                                </div>
                                <div><MyButton primary onClick={() => navigate(`/room?roomId=${lobby.uuid}`)}> Join </MyButton></div>
                            </div>

                        </Card>
                    )
                )}
            </div>
        </div>
    );
};

export default PublicRoomInfoComponent;