import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BE_URL } from '../../../../../common/Constants';
import Room from '../../../../../domain/entities/Room';
import { CreateRoomDTO, CreateRoomRequestBody, Lobby } from '../../../../../common/types';
import * as Sentry from '@sentry/react';

// DEV ONLY!!! (To see Loading Spinner)
const pause = (duration:number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, duration);
    });
};

const roomApi = createApi({
    reducerPath: 'pictoparty',
    baseQuery: fetchBaseQuery({
        baseUrl: BE_URL,
        fetchFn: async (...args) => {
            // REMOVE FOR PRODUCTION
            // await pause(2000); // To mimic API delay (for spinners, etc).
            try {
                const response = await fetch(...args);

                // If the response is not ok (like 404, 500), throw an error
                if (!response.ok) {
                    // Extract the URL from the args[0] (which could be a string or Request object)
                    const url = typeof args[0] === 'string' ? args[0] : args[0].url;

                    const error = new Error(`HTTP error! status: ${response.status}`);
                    // Capture the error in Sentry
                    Sentry.captureException(error, {
                        tags: { apiEndpoint: url }, // Use the extracted URL
                        extra: {
                            status: response.status,
                            statusText: response.statusText,
                            url: response.url,
                        },
                    });
                    throw error; // Continue throwing the error for RTK Query to handle
                }

                return response;
            } catch (error) {
                // Extract the URL again if an error occurs
                const url = typeof args[0] === 'string' ? args[0] : args[0].url;

                // Log error to Sentry
                Sentry.captureException(error, {
                    tags: { apiEndpoint: url }, // Use the extracted URL
                });
                throw error; // Re-throw the error for Redux Query to manage failure states
            }
        },
    }),
    tagTypes: ['Room', 'Lobby'],
    endpoints(builder) {
        return {
            getRoomInfo: builder.query<Room, string>({
                providesTags: (res, err, roomId) => [
                    { type: 'Room' as const, id: roomId },
                    { type: 'Room' as const, id: "The-Room" }
                ],
                query: (roomId) => ({
                    url: `/api/room`,
                    params: { roomId },
                    method: 'GET',
                }),
            }),
            getLobbyInfo: builder.query<Lobby[], void>({
                providesTags: (res, err) => [{ type: 'Lobby' as const, id:"The-Lobby" }],
                query: () => ({
                    url: `/api/lobby`,
                    method: 'GET',
                }),
            }),
            createRoom: builder.mutation<CreateRoomDTO, CreateRoomRequestBody>({
                invalidatesTags: (res, err, roomRequest) => [{ type: 'Room' as const, id: "The-Room" }],
                query: (roomRequest) => ({
                    url: `/api/create_room`,
                    method: 'POST',
                    body: roomRequest,
                }),
            }),
        };
    },
});

export const {
    useGetRoomInfoQuery,
    useGetLobbyInfoQuery,
    useCreateRoomMutation,
} = roomApi;

export default roomApi;