import React from "react";
import classNames from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
  outline?: boolean;
  rounded?: boolean;
  disabled?:boolean;
}

const onlyOneVariationTrue = (props: ButtonProps): boolean => {
  const count =
    Number(!!props.primary) +
    Number(!!props.secondary) +
    Number(!!props.warning) +
    Number(!!props.success) +
    Number(!!props.danger);

  return count <= 1;
};

const MyButton: React.FC<ButtonProps> = ({
  children,
  primary,
  secondary,
  success,
  warning,
  danger,
  outline,
  rounded,
  disabled=false,
  ...rest
}) => {
  if (!onlyOneVariationTrue({ primary, secondary, success, warning, danger })) {
    throw new Error(
      "Only one of primary, secondary, success, warning, danger can be true"
    );
  }

  const classes = classNames(
    "flex items-center px-1.5 py-1 border",
    {
      "border-blue-500 bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600 active:bg-blue-400 border-blue-500": primary,
      "border-gray-900 bg-gray-900 text-white hover:bg-gray-950 focus:bg-gray-950 active:bg-gray-800": secondary,
      "border-green-500 bg-green-500 text-white hover:bg-green-600 focus:bg-green-600 active:bg-green-400": success,
      "border-yellow-400 bg-yellow-400 text-white hover:bg-yellow-600 focus:bg-yellow-600 active:bg-yellow-300": warning,
      "border-red-500 bg-red-500 text-white hover:bg-red-600 focus:bg-red-600 focus:bg-red-400": danger,
      "rounded-full": rounded,
      "bg-white": outline,
      "text-blue-500": outline && primary,
      "text-gray-900": outline && secondary,
      "text-green-500": outline && success,
      "text-yellow-400": outline && warning,
      "text-red-500": outline && danger,
      "bg-gray-400 cursor-not-allowed hover:bg-gray-500 focus:bg-gray-500  active:bg-gray-500" : disabled,
    },
    rest.className
  );

  return (
    <button {...rest} className={classes} disabled={disabled}>
      {children}
    </button>
  );
};

export default MyButton;
