import { useEffect, useRef, useState } from "react";
import { getUserId, useAppSelector } from "../../store/store";

import { getAllMessages } from "../../store/store";
import Chat from "../../../../../domain/entities/Chat";
import { ID_ADMIN, MessageType, UNKNOWN_IMG_ID } from "../../../../../common/Constants";
import { formatEpochSecsToString, getUserFromUserId } from "../../common/utils";
import { FaArrowCircleDown } from "react-icons/fa";
import useDetectKeyboardOpen from "use-detect-keyboard-open";


type TextColorObject = {
  textCol: string;
  bgCol: string;
  hoverColor: string;
};

const getChatTextAnddBgColor = (chat: Chat): TextColorObject => {
  switch (chat.type) {
    case MessageType.PLAYER_CHOOSING_WORD:
    case MessageType.NORMAL_CHAT:
      return { textCol: "text-slate-900", bgCol: "", hoverColor:"hover:bg-gray-50"};

    case MessageType.PLAYER_CONNECTED:
    case MessageType.PLAYER_REJOINED:
    case MessageType.WORD_REVEAL:
    case MessageType.CORRECT_GUESS:
    case MessageType.GAME_ENDED:
      return { textCol: "text-green-900", bgCol: "bg-green-200/60" , hoverColor:"hover:bg-green-300/60"};

    case MessageType.PLAYER_DISCONNECTED:
    case MessageType.PLAYER_LEFT:
      return { textCol: "text-red-900", bgCol: "bg-red-200/60" , hoverColor:"hover:bg-red-300/60"};

    case MessageType.PLAYER_DRAWING:
      return { textCol: "text-blue-900", bgCol: "bg-blue-200/60" , hoverColor:"hover:bg-blue-300/60"};

    default:
      return { textCol: "text-slate-900", bgCol: "", hoverColor:"hover:bg-gray-50"};
  }
};

export const getChatAudio = (chat: Chat): any => {
  switch (chat.type) {
    case MessageType.PLAYER_CONNECTED:
    case MessageType.PLAYER_REJOINED:
      return 'connect.mp3';
    default:
      return 'connect.mp3';
  }
};

function ChatHistory() {
  const msgList = useAppSelector(getAllMessages);
  const messagesEndRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const containerRef = useRef(null); // Ref for the chat history container
  const userId = useAppSelector(getUserId);
  //const isKeyboardOpen = useDetectKeyboardOpen();

  const scrollToBottom = () => {
    if(!containerRef || !containerRef.current) return;
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  };

  const onScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    // Define a tolerance threshold (e.g., 5 pixels) to consider the user "at the bottom"
    const bottomTolerance = 100;
    const isUserAtBottom =
      scrollHeight - scrollTop - clientHeight < bottomTolerance;
    setIsAtBottom(isUserAtBottom);
  };

  useEffect(() => {
    if (msgList && msgList.length && isAtBottom ) {
      scrollToBottom();
    }
  }, [msgList, isAtBottom]);

  useEffect(() => {
    const container = containerRef.current;
    if(!container)
      return;
    container.addEventListener("scroll", onScroll);

    return () => container.removeEventListener("scroll", onScroll);
  }, []);

  const renderChatMessage = (chat: Chat) => {
    const colors = getChatTextAnddBgColor(chat);
    const foundUser = getUserFromUserId(chat.playerId);
    
    return (
      
        <li className="border-b border-gray-400" key={chat.uid}>
          <a
            className={`flex w-full px-1.5 py-1.5 ${colors.textCol} ${colors.bgCol} ${colors.hoverColor}`}
          >
            {chat.playerId !== userId ? <img
              className="block m-1 rounded-full w-9 h-9"
              src={`${foundUser? foundUser.avatarId: chat.playerId===ID_ADMIN ? ID_ADMIN : UNKNOWN_IMG_ID}.svg`}
              alt={`${foundUser? foundUser.userName: chat.playerId===ID_ADMIN ? ID_ADMIN :'Unknown user'}'s Avatar`}
            /> : null }
            {chat.playerId === userId ? <div className="block w-6 h-6">{" "}</div>:null}
            <div className="block flex-grow">
              {chat.playerId !== userId ? 
              <div className="flex justify-between">
                <p className={`${chat.playerId === ID_ADMIN ? 'italic':''} font-medium text-xs text-gray-900 `}>
                  {chat.userName}:
                </p>
                <p className={`text-[10px] text-blue-600`}>
                  {formatEpochSecsToString(chat.createdAt)}
                </p>
              </div>
              :
              <div className="flex justify-between flex-row-reverse">
                <p className="italic font-medium text-xs text-gray-900">
                  You:
                </p>
                <p className={`text-[10px] text-blue-600`}>
                  {formatEpochSecsToString(chat.createdAt)}
                </p>
              </div>
              }
              <p className={`whitespace-normal break-all leading-tight ${chat.playerId === ID_ADMIN ? 'italic':''} text-sm ${colors.textCol} ${chat.playerId === userId ? 'text-end':'text-start'}`}>
                {chat.messageTransformed}
              </p>
            </div>
            {chat.playerId !== userId ? <div className="block w-6 h-6">{" "}</div>:null}
            {chat.playerId === userId ? <img
              className="block m-1 rounded-full w-9 h-9"
              src={`${foundUser? foundUser.avatarId: chat.playerId===ID_ADMIN ? ID_ADMIN : UNKNOWN_IMG_ID}.svg`}
              alt={`${foundUser? foundUser.userName: chat.playerId===ID_ADMIN ? ID_ADMIN :'Unknown user'}'s Avatar`}
            /> : null }
          </a>
        </li>
    );
  };

  return (
    <div
      className="flex-grow h-1/2 relative w-full overflow-auto bg-white border border-gray-100 rounded-lg dark:bg-gray-700 dark:border-gray-600"
      ref={containerRef}
      onScroll={onScroll}
    >
      {!isAtBottom && (
        <button
          onClick={scrollToBottom}
          className="sticky top-5 lg:top-10 left-1/2 transform -translate-x-1/2 bg-slate-600/50 px-2 py-1 rounded"
        >
          <FaArrowCircleDown className="h-7 w-7 lg:h-14 lg:w-14"/>
        </button>
      )}
      <ul>{msgList.map((chat) => renderChatMessage(chat))}</ul>
      <div ref={messagesEndRef} />{" "}
      {/* Invisible element at the end of the list */}
    </div>
  );
}

export default ChatHistory;

