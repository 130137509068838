import useTimer from '../common/useTimer';

function TimerBar() {
      const {seconds, duration} = useTimer();

        const percentage = (seconds / (duration)) * 100;

        let gradientColor;
        if (percentage > 66) {
          gradientColor = "to-green-500 from-green-600";
        } else if (percentage > 33) {
          gradientColor = "to-yellow-500 from-yellow-600";
        } else {
          gradientColor = "to-red-500 from-red-600";
        }
        const isBlinking = percentage < 25;

      return (
        <div className="w-3 h-full rounded-full border-2 border-slate-950 relative overflow-hidden bg-gray-200">
          <div
            className={`absolute bottom-0 left-0 w-full bg-gradient-to-t ${gradientColor} ${
              isBlinking ? "animate-[pulse_0.2s_ease-in-out_infinite]" : ""
            }`}
            style={{ height: `${percentage}%`, transition: "height .5s" }}
          ></div>
          <div
            className="absolute top-0 left-0 w-full bg-gradient-to-t from-gray-800 to-gray-950"
            style={{ height: `${100 - percentage}%`, transition: "height .5s" }}
          ></div>
        </div>
      );
}

export default TimerBar