import { useState, useCallback } from 'react';

const useCooldownClick = (onClick: () => void, cooldown: number): [() => void, boolean] => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = useCallback(() => {
    if (!isDisabled) {
      onClick();
      setIsDisabled(true);
      setTimeout(() => setIsDisabled(false), cooldown);
    }
  }, [onClick, cooldown, isDisabled]);

  return [handleClick, isDisabled];
};

export default useCooldownClick;