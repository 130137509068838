import { useState } from "react";
import { socketSendChatMessage } from "../../socket";
import MyButton from "../ui/Button";
import useCooldownClick from "../../common/useCooldownClick";
import { Spinner, TextInput } from "flowbite-react";
import { IoIosSend } from "react-icons/io";

function ChatInput() {
  const [message, setMessage] = useState<string>("");
  const [handleCooldownClick, isDisabled] = useCooldownClick(handleSendMessage, 700);

  const handleInputChange = (e: any) => {
    setMessage(e.target.value);
  };

  function handleSendMessage() {
    if (message.trim()) {
      socketSendChatMessage(message.trim());
      setMessage(""); // Clear the input field after sending
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents the default action of the Enter key (new line)
      handleCooldownClick();
    }
  };

  return (
    <div className="flex items-center justify-between">
      <TextInput
        placeholder="Type your guess/chat here"
        type="text"
        sizing="sm"
        value={message}
        color={isDisabled?"warning":"base"}
        className={`${isDisabled?'text-blue-700':'text-gray-800'} flex-grow p-1 mr-1 focus:outline-none focus:border-blue-500`}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <MyButton
        onClick={handleCooldownClick}
        className="text-white place-content-center rounded-lg p-1 h-8 w-8 bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 active:bg-blue-600"
        disabled={isDisabled}
      >
        {isDisabled?<Spinner/>:<IoIosSend/>}
      </MyButton>
    </div>
  );
}

export default ChatInput;
