import { useEffect, useRef } from "react";
import { nanoid } from "@reduxjs/toolkit";
import DrawSegment from "../../../../../domain/entities/DrawSegment";
import Point from "../../../../../domain/entities/Point";
import { LINE_ID_LENGTH } from "../../../../../common/Constants";

export type onDraw = (point: Point, prevPoint: Point, lineIdRef: string) => void;
export type onDrawProgrammatically = (
  ctx: CanvasRenderingContext2D | null,
  lineSegmentDrawData: DrawSegment
) => void;

export function useOnDraw(
  onDraw: onDraw,
  onDrawProgrammatically: onDrawProgrammatically
) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const isDrawingRef = useRef(false);
  const prevPointRef = useRef<Point | null>(null);
  const lineIdRef = useRef<string>(nanoid(LINE_ID_LENGTH));

  function setCanvasRef(ref: HTMLCanvasElement) {
    canvasRef.current = ref;
  }
  function drawLineDataProgramatically(drawData: DrawSegment) {
    if (!canvasRef.current || !onDrawProgrammatically || !drawData) return;
    const ctx = canvasRef.current.getContext("2d");
    onDrawProgrammatically(ctx, drawData);
  }

  function resetCanvas() {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext("2d");
    ctx?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  }
  function computePointInCanvas(
    clientX: number,
    clientY: number
  ): Point | null {
    if (canvasRef.current) {
      const boundingRect = canvasRef.current.getBoundingClientRect();
      return {
        x:
          ((clientX - boundingRect.left) * canvasRef.current.width) /
          boundingRect.width,
        y:
          ((clientY - boundingRect.top) * canvasRef.current.height) /
          boundingRect.height,
      };
    } else {
      return null;
    }
  }
  
    function handlePointerDown(e: React.PointerEvent<HTMLCanvasElement>) {
      if (!e.isPrimary) return; // Ensure only primary pointer (usually left mouse button) starts drawing
      isDrawingRef.current = true;
      const point = computePointInCanvas(e.clientX, e.clientY);
      if (point) {
        prevPointRef.current = point;
        lineIdRef.current = nanoid(LINE_ID_LENGTH);
        e.preventDefault(); // Prevent default behavior
      }
    }

  function handlePointerMove(e: PointerEvent) {
    if (!isDrawingRef.current || !canvasRef.current) return;
    const point = computePointInCanvas(e.clientX, e.clientY);
    if (point && prevPointRef.current && lineIdRef.current) {
      onDraw(point, prevPointRef.current, lineIdRef.current);
      prevPointRef.current = point;
    }
    e.preventDefault(); // Prevent default behavior
  }

  function handlePointerUp() {
    isDrawingRef.current = false;
    prevPointRef.current = null;
  }

  function handlePointerLeave() {
    if (isDrawingRef.current) {
      isDrawingRef.current = false;
      prevPointRef.current = null;
    }
  }

  function handlePointerEnter(e: PointerEvent) {
    if (e.buttons === 1 && !isDrawingRef.current) {
      isDrawingRef.current = true;
      const point = computePointInCanvas(e.clientX, e.clientY);
      if (point) {
        prevPointRef.current = point;
      }
      e.preventDefault(); // Prevent default behavior
    }
  }

  function handleTouchStart(e: TouchEvent) {
    if (e.touches.length !== 1) return; // Ensure only single touch is processed
    e.preventDefault(); // Prevent default touch behavior
    isDrawingRef.current = true;
    const touch = e.touches[0];
    const point = computePointInCanvas(touch.clientX, touch.clientY);
    if (point) {
      prevPointRef.current = point;
      lineIdRef.current = nanoid(LINE_ID_LENGTH);
    }
  }

  function handleTouchMove(e: TouchEvent) {
    e.preventDefault(); // Prevent default touch behavior
    if (!isDrawingRef.current || !canvasRef.current || e.touches.length !== 1)
      return;
    const touch = e.touches[0];
    const point = computePointInCanvas(touch.clientX, touch.clientY);
    if (point && prevPointRef.current && lineIdRef.current) {
      onDraw(point, prevPointRef.current, lineIdRef.current);
      prevPointRef.current = point;
    }
  }

  function handleTouchEnd(e: TouchEvent) {
    e.preventDefault(); // Prevent default touch behavior
    isDrawingRef.current = false;
    prevPointRef.current = null;
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    // @ts-ignore
    canvas.addEventListener("pointerdown", handlePointerDown);
    canvas.addEventListener("pointermove", handlePointerMove);
    canvas.addEventListener("pointerup", handlePointerUp);
    canvas.addEventListener("pointerleave", handlePointerLeave);
    canvas.addEventListener("pointerenter", handlePointerEnter);

    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchmove", handleTouchMove);
    canvas.addEventListener("touchend", handleTouchEnd);

    return () => {
      // @ts-ignore
      canvas.removeEventListener("pointerdown", handlePointerDown);
      canvas.removeEventListener("pointermove", handlePointerMove);
      canvas.removeEventListener("pointerup", handlePointerUp);
      canvas.removeEventListener("pointerleave", handlePointerLeave);
      canvas.removeEventListener("pointerenter", handlePointerEnter);

      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
      canvas.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  return {
    drawLineDataProgramatically,
    setCanvasRef,
    handlePointerDown,
    resetCanvas,
  };
}
