import React from 'react';
import { getGifData, useAppSelector } from '../../store/store';

const GifDisplay: React.FC = () => {
  const gifData = useAppSelector(getGifData);

  return (
    <div className="flex justify-center items-center h-fit">
      {gifData && gifData.imgData ? (
        <img src={gifData.imgData} alt="Generated GIF" />
      ) : (
        <p>No GIF generated yet.</p>
      )}
    </div>
  );
};

export default GifDisplay;