import { useCallback, useEffect, useRef } from "react";
import {
  getLastTurn,
  getUserCanvasPrefs,
  requestRepaintAndResetToDrawQueueAndAndAddTheseEdges,
  store,
  useAppDispatch,
  useAppSelector,
  useDeepEqualSelector,
} from "../../store/store";

import { useOnDraw } from "./useOnDraw";
import {
  addEdgesToDrawQueue,
  getToDrawQueueData,
  isCanvasCleanUpRequired,
  resetToDrawQueueOnly,
  setCanvasCleanUpRequiredOnly,
} from "../../store/store";
import { socketSendDrawLineData } from "../../socket";
import { allowedToDraw, drawLineSegment, relaxPointPrecision } from "../../common/utils";
import { addToBatch } from "../../common/batchUtils";
import Point from "../../../../../domain/entities/Point";
import DrawSegment from "../../../../../domain/entities/DrawSegment";
import { CANVAS_HEIGHT_PX, CANVAS_WIDTH_PX, TurnStatus } from "../../../../../common/Constants";
import BrushTip from "./BrushTip";

// Source: https://github.com/R4M5E5/Video-Tutorial-Code-React-Drawing-App/blob/main/src/Components/Hooks.js
// Videos: [ https://www.youtube.com/watch?v=GpAIlGRfl_8 , https://www.youtube.com/watch?v=MXSuOR2yRvQ , https://www.youtube.com/watch?v=0bAXbFj7zb8 ]

type CanvasProps = {
  width: number;
  height: number;
  userId: string;
};

const Canvas = ({ width, height, userId }: CanvasProps) => {
  const {
    drawLineDataProgramatically,
    setCanvasRef,
    handlePointerDown,
    resetCanvas,
  } = useOnDraw(onDraw, onDrawProgrammatically);

  const canvasCleanupRequired = useAppSelector(isCanvasCleanUpRequired);
  const toDrawQueueData = useDeepEqualSelector(getToDrawQueueData);
  const turnStatus = useAppSelector(getLastTurn)?.status;
  const canvasPrefData = useAppSelector(getUserCanvasPrefs);

  const dispatch = useAppDispatch();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  /**
   * 0. Drawer Draws: Just send coords to redux and socket
   * ❗: Shouldn't use any variable outside the func reference (else stale referenes possible due to closure)
   */
  function onDraw(point: Point, prevPoint: Point, lineIdRef: string) {
    // stale closure reference. Needs refetch
    if (!allowedToDraw()) {
      //todo: Optionally thrown toast, or change cursor, tooltip
      return;
    }

    // stale closure reference. Needs refetch
    const userCanvasPrefData = getUserCanvasPrefs(store.getState());
    const color = userCanvasPrefData.hexColor;
    const thickness = userCanvasPrefData.lineWidth;

    point = relaxPointPrecision(point);
    prevPoint = relaxPointPrecision(prevPoint);
    const lineSegmentDrawData: DrawSegment = {
      seg: [{ i: prevPoint, f: point }],
      cl: color,
      th: thickness,
      dId: userId,
      sId: lineIdRef,
      pt: userCanvasPrefData.eraserOrBrushSelected, //todo: get from redux, using current tool selected.
      uAt: new Date().getTime(),
    };
    dispatch(addEdgesToDrawQueue([lineSegmentDrawData]));
    addToBatch(lineSegmentDrawData, socketSendDrawLineData);
  }

  /**
   * 1. Observe Redux toDrawQueue state (and if clean up needed), whether drawer drew, or receiver received,
   *    and initiates draw routine.
   */
  useEffect(() => {
    // Canvas Clean up if needed:
    if (canvasCleanupRequired) {
      resetCanvas();
      dispatch(setCanvasCleanUpRequiredOnly(false));
    }
    // Drawing lines in toDrawQueue and flushing the queue afterwards (since lines drawn)
    toDrawQueueData.forEach((lineSegmentDrawData) => {
      drawLineDataProgramatically(lineSegmentDrawData);
    });
    dispatch(resetToDrawQueueOnly());
  }, [canvasCleanupRequired, toDrawQueueData]);

  // On every newly created turn, empty the canvas. Clears screen for drawer of previous turn. 
  // Ensures no artifacts of prev turn remain.
  useEffect(() => {
    if ( turnStatus === TurnStatus.CREATED || turnStatus === TurnStatus.PREPPED || turnStatus === TurnStatus.STARTED) {
      dispatch(requestRepaintAndResetToDrawQueueAndAndAddTheseEdges([]));
    }
  }, [turnStatus]);

  /**
   * 2. Actually Draws line (Whether Drawer draws, or receiver receives)
   * ❗: Shouldn't use any variable outside the func references (else stale referenes possible)
   */
  function onDrawProgrammatically(
    ctx: CanvasRenderingContext2D | null,
    lineSegmentDrawData: DrawSegment
  ) {
    let { seg, cl, th, pt } = lineSegmentDrawData;
    for (const segment of seg) {
      if (!ctx || !segment.i || !segment.f || !cl || !th || pt === undefined || pt === null) {
        continue;// todo: skip iteration or return from function?
      }
      drawLineSegment(ctx, segment.i, segment.f, cl, th, pt);
    }
  }

  const canvasRefHelper = useCallback((node: HTMLCanvasElement | null) => {
    if (!node) {
      return;
    }
    if (canvasRef.current !== node) {
      canvasRef.current = node;
      setCanvasRef(node);
    }
  }, []);

  return (
    <>
      <canvas
        width={width}
        height={height}
        onPointerDown={handlePointerDown}
        style={canvasStyle}
        ref={canvasRefHelper}
        className={`${!allowedToDraw() ? "cursor-not-allowed" : "cursor-crosshair"} bg-white`}
      />
      {allowedToDraw() &&
        <BrushTip canvasRef={canvasRef} brushRadiusPx={canvasPrefData.lineWidth / 2} brushColorHex={canvasPrefData.hexColor} />
      }
    </>
  );
};

export default Canvas;

export const canvasStyle = {
  border: "1px dashed black",
  height: "auto",
  width: "97%",
  aspectRatio: `auto ${CANVAS_WIDTH_PX}/${CANVAS_HEIGHT_PX}`,
};
