import React, { ReactNode, useEffect, useState } from "react";
import MyButton from "./Button";
import { startGame, updateGameSettings } from "../../socket";
import { getGameSettings, getLastTurn, useAppSelector } from "../../store/store";
import { ClientGameSetting, ClientPlayerData } from "../../../../../common/types";
import {
  DEFAULT_MAX_PLAYERS,
  DRAWING_DURATION,
  GameStatus,
  MAX_DRAWING_DURATION,
  MAX_NO_OF_ROUNDS,
  MAX_PLAYERS,
  MAX_PREP_DURATION,
  MAX_WORD_SELECTION_DURATION,
  MIN_DRAWING_DURATION,
  MIN_HINT_DELAY,
  MIN_NO_OF_ROUNDS,
  MIN_PLAYERS,
  MIN_PREP_DURATION,
  MIN_WORD_SELECTION_DURATION,
  NO_OF_ROUNDS,
  PREP_DURATION,
  TurnStatus,
  WORD_SELECTION_DURATION,
} from "../../../../../common/Constants";
import ToggleInput from "./ToggleInput";
import { MdChatBubble, MdGif, MdLeaderboard, MdSettings  } from "react-icons/md";
import Leaderboard from "../LeaderBoard";
// import { getDisconnectedUsers } from "../../common/utils";
// import { RiWifiOffFill } from "react-icons/ri";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ConfettiComponent from "./ConfettiElement";
import SliderInput from "./SliderInput";
import { getDisconnectedUsers } from "../../common/utils";
import GifContainer from "../gif/GifContainer";
import ChatSection from "../chat/ChatSection";
// import BuyMeACoffeeButton from "../BuyMeCoffeeButton";

export enum LandingTab {
  LEADERBOARD = 'leaderboard',
  SETTINGS = 'settings',
  GIF = 'gif',
  CHAT = 'chat'
}

const TabsCssClasses = {
  base: 'rounded-md hover:scale-110 focus:scale-110 active:scale-105 focus:bg-slate-500 active:bg-slate-400 hover:bg-slate-500 m-1 p-1',
  normal: 'bg-slate-200',
  active: 'bg-slate-400 scale-105 border-2 border-gray-800',
}
interface WaitingLobbyScreenProps {
  leftPanelContent: ReactNode;
  readOnly: boolean;
  landingTab: LandingTab;
  userList: ClientPlayerData[];
  gameStatus: GameStatus;
}

const WaitingLobbyScreen: React.FC<WaitingLobbyScreenProps> = ({
  leftPanelContent,
  readOnly,
  landingTab,
  userList,
  gameStatus,
}) => {
  const roomInfo = useAppSelector(getGameSettings);
  const [formValues, setFormValues] = useState<ClientGameSetting>({
    minPlayers: roomInfo?.minPlayers || MIN_PLAYERS,
    maxPlayers: roomInfo?.maxPlayers || DEFAULT_MAX_PLAYERS,
    noOfRounds: roomInfo?.noOfRounds || NO_OF_ROUNDS,
    drawingDuration: roomInfo?.drawingDuration || DRAWING_DURATION,
    wordSelectionDuration:
      roomInfo?.wordSelectionDuration || WORD_SELECTION_DURATION,
    prepDuration: roomInfo?.prepDuration || PREP_DURATION,
    hintDisabled: roomInfo?.hintDisabled || false,
    hintDelay: roomInfo?.hintDelay || MIN_HINT_DELAY,
  });
  const [dirtyForm, setDirtyForm] = useState(false);
  const [selectedTab, setSelectedTab] = useState(landingTab);

  const lastTurn = useAppSelector(getLastTurn);
  const showConfetti = !!(lastTurn && lastTurn.status === TurnStatus.COMPLETED && gameStatus === GameStatus.FINISHED && landingTab === LandingTab.LEADERBOARD);

  // If admin updates settinfs, then this useEffect will listen for settings
  // update (which is captured in redux). Will update local component form state.
  // This updates form values for viewers.
  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      ...roomInfo,
    }));
  }, [roomInfo]);

  const handleChange = (name: string, value: number | boolean) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setDirtyForm(true);
  };

  const handleSubmit = (event: React.PointerEvent<HTMLButtonElement>) => {
    event.preventDefault();
    updateGameSettings(formValues);
    setDirtyForm(false);
  };

  function renderSettings(){
    return (<>
  
    <div className="relative bg-slate-100 p-4 border-gray-400 border-2 rounded-lg flex flex-col gap-8">
      <SliderInput
        label="Min. players:"
        name="minPlayers"
        value={formValues.minPlayers}
        onChange={handleChange}
        paragraphText={`Please select min no of players (${MIN_PLAYERS}-${formValues.maxPlayers}) to start the game.`}
        minValue={MIN_PLAYERS}
        maxValue={formValues.maxPlayers}
        readOnly={readOnly}
        labelUnit="players"
      />
      <SliderInput
        label="Max. players:"
        name="maxPlayers"
        value={formValues.maxPlayers}
        onChange={handleChange}
        paragraphText={`Please select max no of players (${formValues.minPlayers}-${MAX_PLAYERS}) possible in game.`}
        minValue={formValues.minPlayers}
        maxValue={MAX_PLAYERS}
        readOnly={readOnly}
        labelUnit="players"
      />
      <SliderInput
        label="No. of Rounds:"
        name="noOfRounds"
        value={formValues.noOfRounds}
        onChange={handleChange}
        paragraphText={`Please select no of rounds (${MIN_NO_OF_ROUNDS} to ${MAX_NO_OF_ROUNDS}) in game. In every round, each player will draw once, and guess everyone's drawing once`}
        minValue={MIN_NO_OF_ROUNDS}
        maxValue={MAX_NO_OF_ROUNDS}
        readOnly={readOnly}
        labelUnit="rounds"
      />
      <SliderInput
        label="Drawing Duration:"
        name="drawingDuration"
        value={formValues.drawingDuration}
        onChange={handleChange}
        paragraphText={`Please select Drawing Duration (${MIN_DRAWING_DURATION} to ${MAX_DRAWING_DURATION} sec), the total duration a drawer and guessers get to work on a word.`}
        minValue={MIN_DRAWING_DURATION}
        maxValue={MAX_DRAWING_DURATION}
        readOnly={readOnly}
        labelUnit="sec"
      />
      <SliderInput
        label="Word Selection Duration:"
        name="wordSelectionDuration"
        value={formValues.wordSelectionDuration}
        onChange={handleChange}
        paragraphText={`Drawer gets this much time to choose to draw. Between ${MIN_WORD_SELECTION_DURATION} to ${MAX_WORD_SELECTION_DURATION} sec.`}
        minValue={MIN_WORD_SELECTION_DURATION}
        maxValue={MAX_WORD_SELECTION_DURATION}
        readOnly={readOnly}
        labelUnit="sec"
      />
      <SliderInput
        label="Buffer time Duration:"
        name="prepDuration"
        value={formValues.prepDuration}
        onChange={handleChange}
        paragraphText={`Buffer time between turn switches. Between ${MIN_PREP_DURATION} to ${MAX_PREP_DURATION} sec.`}
        minValue={MIN_PREP_DURATION}
        maxValue={MAX_PREP_DURATION}
        readOnly={readOnly}
        labelUnit="sec"
      />
      <ToggleInput
        label="Disable hint"
        name="hintDisabled"
        value={formValues.hintDisabled}
        paragraphText={`If hints disabled, then word characters will not be gradually revealed during a turn.`}
        readOnly={readOnly}
        onChange={handleChange}
      />
      {/* <SliderInput
        label="Min. hint delay:"
        name="hintDelay"
        value={formValues.hintDelay}
        onChange={handleChange}
        paragraphText="Please select hint delay in seconds"
        minValue={MIN_HINT_DELAY}
        maxValue={MAX_HINT_DELAY}
        readOnly={readOnly}
        labelUnit="sec"
      /> */}
    </div>
  </>)
  }

  function renderTabContent(): JSX.Element{
    if(selectedTab === LandingTab.SETTINGS){
      return wrapWithSectionConstraintsAndDetails("Settings", renderSettings());
    }else if(selectedTab === LandingTab.LEADERBOARD){
      return wrapWithSectionConstraintsAndDetails("Leaderboard", <Leaderboard />);
    }else if(selectedTab === LandingTab.GIF){
      return wrapWithSectionConstraintsAndDetails("Gif memory 💗", <GifContainer />);
    }else if(selectedTab === LandingTab.CHAT){
      return wrapWithSectionConstraintsAndDetails("Chat", <ChatSection />);
    }
    console.error("Unhandled selectedTab case");
    return <p>Have't thought of that yet!😋</p>
  }

  function wrapWithSectionConstraintsAndDetails(sectionName:string,content: JSX.Element):JSX.Element{
    return <div>
      <div className="font-bold text-md m-2 text-center">{sectionName}</div>
      <div className="h-[290px] md:h-[376px] overflow-auto w-[250px] mx-auto p-1">{content}</div>
      </div>;
  }

  const onClickcopyToClipboard = async () => {
    try {
      const roomLink = window.location.href;
      await navigator.clipboard.writeText(roomLink);
      toast.info("Invite link copied!");
    } catch (err) {
      toast.error('Failed to copy invite link!');
      console.error('Failed to copy invite link: ', err);
    }
  }

  function getTabStyles(thisTab: LandingTab){
    return `${selectedTab === thisTab ? TabsCssClasses.active : TabsCssClasses.normal} ${TabsCssClasses.base}`;
  }

  function renderTabItemsOnSidebar(){
    return <>
      <button onClick={()=>setSelectedTab(LandingTab.SETTINGS)} className={getTabStyles(LandingTab.SETTINGS)}><MdSettings className="h-8 w-8"/></button>
      <button onClick={()=>setSelectedTab(LandingTab.LEADERBOARD)} className={getTabStyles(LandingTab.LEADERBOARD)}><MdLeaderboard className="h-8 w-8"/></button>
      <button onClick={()=>setSelectedTab(LandingTab.GIF)} className={getTabStyles(LandingTab.GIF)}><MdGif className="h-8 w-8"/></button>
      <button onClick={()=>setSelectedTab(LandingTab.CHAT)} className={getTabStyles(LandingTab.CHAT)}><MdChatBubble className="h-8 w-8"/></button>
    </>;
  }

  function renderCTAs(){
    return <>
      <div>
        {readOnly ? (
          <div className="font-bold motion-safe:animate-pulse" style={{animationDuration: '.8s'}}>"Waiting for Admin to start game."</div>
        ) : (
          <MyButton primary onClick={startGame} disabled={isStartButtonDisabled} className="text-sm rounded-lg">
            Start Game
          </MyButton>
        )}
      </div>
      <div>
        <MyButton primary onClick={onClickcopyToClipboard} className="text-sm rounded-lg">
          <FaRegCopy />Copy Invite!
        </MyButton>
      </div>
      <div>
      {!readOnly && <MyButton primary disabled={!dirtyForm} className={`${dirtyForm?"cursor-pointer":"cursor-not-allowed"} text-sm rounded-lg`} onClick={handleSubmit}> {dirtyForm?<span className={`${dirtyForm?"motion-safe:animate-pulse":""}`} style={{animationDuration: '.5s'}}>👉</span>:<span>✅</span>} Update Settings</MyButton>}
      </div>
    </>;
  }

  function renderPlayerList(){
    return <div className="mx-auto h-40 flex md:h-[500px] flex-col gap-2 place-content-center">
            <div className="h-8 md:h-12 px-4 md:px-6 py-0 md:py-2 text-lg font-black">
              Players {userList.length ? `(${userList.length})`: null} 
              {/* Unconnected users size */}
              {/* {unConnectedUsers.length? <span>(<RiWifiOffFill color="black" title="Disconnected user" className="inline-block" />{unConnectedUsers.length})</span>:null} */}
            </div>
            <div className="flex-1 flex flex-row md:flex-col overflow-auto p-0">
              {leftPanelContent}
            </div>
          </div>;
  }

  const unConnectedUsers = getDisconnectedUsers(userList);

  const isStartButtonDisabled = !(userList && (userList.length - unConnectedUsers.length >= (roomInfo?.minPlayers||0)));
  console.log();
  return (
    <div className="flex min-h-screen items-center justify-center bg-slate-200 dark:bg-slate-800 text-slate-900">
      {/* Confetti: on Game End (when showing leaderboard) 
        🤜 todo: Should show nly once for game? Currently if you click Logo (to goto hame screen), and come back to leaderboard (pressing back arrow), then confetti again shown for same game results.
      */}
      <ConfettiComponent showConfetti={showConfetti}/>
      <div className="mx-auto w-full sm:max-w-screen-md">
        {/**
         * Logo */}
        <div className="flex justify-center">
          <Link to="/" className="m-1 p-2 bg-slate-100 rounded-lg border-2 hover:scale-105 hover:bg-slate-300 border-slate-900 flex gap-1 justify-stretch">
              <div><img src="dd_logo.png" alt="Draw Detective logo" className="h-8 w-8"/></div>
              <div className="text-xl md:text-2xl ">Draw Detective</div>
          </Link>
        </div>
        <div className="container mx-auto p-1 flex flex-col items-center justify-center">
          <div className="flex flex-col md:flex-row items-center justify-center gap-1">
            {/* Players Tab */}
            <div className="w-full md:w-56 h-40 md:h-[500px] bg-blue-200">
              {renderPlayerList()}
            </div>
            {/* Settings/Leaderboard/Gif/Chat & CTA tab */}
            <div className="w-full md:w-68 md:w-80 h-[400px] md:h-[500px] bg-gray-50 p-1">
              <div className="grid grid-flow-row grid-cols-[50px_1fr] grid-rows-[1fr_60px] justify-stretch flex-1 h-full overflow-auto">
                <div className="bg-gray-50 flex flex-col gap-2 justify-start place-items-center">
                  {renderTabItemsOnSidebar()}
                </div>
                <div className="bg-gray-200 h-[330px] md:h-[420px]">
                  {renderTabContent()}
                </div>
                <div className="h-14 px-4 col-span-2 py-1 rounded-xl flex justify-around place-items-center gap-4">
                  {renderCTAs()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingLobbyScreen;
