import { getDrawerId, useAppSelector } from "../store/store";

import { getAllUsers, getUserId } from "../store/store";
import { PlayerStatus } from "../../../../common/Constants";
import { getPlayerScores } from "../store/store";
import { getPlayerRankings, hasPlayerGuessedDrawing, isIdTheDrawerForThisTurn, movePlayerToBeginning } from "../common/utils";
import { RiWifiOffFill } from "react-icons/ri";
import MyAvatar from "./ui/MyAvatar";
import { BiSolidPencil } from "react-icons/bi";
import { Badge } from "flowbite-react";
import { ClientPlayerData } from "../../../../common/types";

export const renderConnectiontatus = (player: ClientPlayerData) => {
  return player.status == PlayerStatus.CONNECTED ? null : (
    <Badge color="warning" size="xs" className='opacity-80'>
      <RiWifiOffFill color="black" title="Disconnected user" />
    </Badge>
  );
}

function UserListSection() {
  const userId = useAppSelector(getUserId);
  let userList = useAppSelector(getAllUsers);
  const drawerId = useAppSelector(getDrawerId);
  const playerScores = useAppSelector(getPlayerScores);

  const playerRankings = getPlayerRankings(playerScores);

  const mockPlayerItem = (player: ClientPlayerData) => {
    return (
      <li
        className={`relative py-3 sm:py-4 ${
          hasPlayerGuessedDrawing(player.uid) ? "bg-green-500" : ""
        }`}
        key={player.uid}
      >
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <div className="h-10 w-10">
              <MyAvatar avatarId={player.avatarId} />
              {userId === player.uid && (
                <Badge
                  color="info"
                  size="xs"
                  className="absolute top-0 right-0 -mt-1 -mr-1 bg-opacity-50"
                >
                  You
                </Badge>
              )}
            </div>
          </div>
          {playerRankings && playerRankings[player.uid] && <Badge
            color="info"
            size="xs"
            className="absolute top-0 left-0 -mt-0.5 -ml-2 bg-opacity-50"
          >
            {`# ${playerRankings[player.uid]}`
              }
          </Badge>
          }
          <div className="flex-1 min-w-0 ms-2">
            <p className="text-xs font-medium text-gray-900 truncate dark:text-white">
              {player.userName}
            </p>
            <div className="flex mr-4 justify-start gap-0.5 text-sm text-gray-500 truncate dark:text-gray-400">
              {renderConnectiontatus(player)}
              <div className="text-xs">
                {`${
                  playerScores && playerScores[player.uid]
                    ? playerScores[player.uid] + " "
                    : "0 "
                }`}
              </div>
              {isIdTheDrawerForThisTurn(player.uid) ? (
                <BiSolidPencil className="h-5 w-5" />
              ) : null}
            </div>
          </div>
          {/* // todo: When super powers enabled, then enable rendering below */}
          {/* <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
            <FaSuperpowers /> <CiAvocado />
          </div> */}
        </div>
      </li>
    );
  };

  if(userList && userId){
    // userList order:
    // - 1️⃣ currentUser:
    // - 2️⃣ current Drawer:
    // - 3️⃣ Ldbd: (May be)
    userList = movePlayerToBeginning(userList, userId); // 1️⃣ currentUser
    if (drawerId && drawerId !== userId) {
      // 2️⃣ currentUser
      userList = movePlayerToBeginning(userList, drawerId);
      const temp = userList[0];
      userList[0] = userList[1];
      userList[1] = temp;
    }
    // 3️⃣ ldbd ranked (May be)
  }

  return (
    <>
      <div className="w-full h-full max-w-md p-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 overflow-y-auto">
        <ul
          role="list"
          className="divide-y divide-gray-200 dark:divide-gray-700"
        >
          {userList && userList.map((u) => mockPlayerItem(u))}
        </ul>
      </div>
    </>
  );
}

export default UserListSection