import React, { useState, useEffect } from 'react';
import { darkenHexColor, hexToRgbA } from '../../common/utils';
import { getLastRoundLastTurnStatus, useAppSelector } from '../../store/store';
type BrushTipProps = {
    canvasRef: React.RefObject<HTMLCanvasElement>;
    brushRadiusPx: number,
    brushColorHex: string,
};

const BrushTip:React.FC<BrushTipProps> = ({ canvasRef, brushRadiusPx, brushColorHex }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isInsideCanvas, setIsInsideCanvas] = useState(false);
  const turnStatus = useAppSelector(getLastRoundLastTurnStatus);


  const updatePosition = (event: PointerEvent) => {
    if(!canvasRef || !canvasRef.current) 
        return;
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX;
    const y = event.clientY;

    if (
      x >= rect.left &&
      x <= rect.right &&
      y >= rect.top &&
      y <= rect.bottom
    ) {
      setPosition({ x, y });
      setIsInsideCanvas(true);
    } else {
      setIsInsideCanvas(false);
    }
  };

  useEffect(() => {
    window.addEventListener('pointermove', updatePosition);
    return () => {
      window.removeEventListener('pointermove', updatePosition);
    };
  }, [canvasRef, turnStatus]);
  
  const scaledBrushRadius = brushRadiusPx / getCanvasScalingFactor();

  function getCanvasScalingFactor(): number {
    if (!canvasRef || !canvasRef.current) return 1;
    const rect = canvasRef.current.getBoundingClientRect();
    return canvasRef.current.width / rect.width;
  }

  return isInsideCanvas ? (
    <div
      className="absolute pointer-events-none rounded-full border-2 border-dashed hidden md:block"
      style={{
        width: `${scaledBrushRadius*2}px`,
        height: `${scaledBrushRadius*2}px`,
        top: `${position.y - scaledBrushRadius}px`,
        left: `${position.x - scaledBrushRadius}px`,
        borderColor: darkenHexColor(brushColorHex, .5),
        backgroundColor: hexToRgbA(brushColorHex, .20),
      }}
    ></div>
  ) : null;
};

export default BrushTip;