import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import { isEqual } from "lodash";

import userListReducer, { resetUserSlice } from "./slices/userListSlice";
import gameDataSlice, { resetGameDataSlice } from "./slices/gameDataSlice";
import pictoPartyApi from "./apis/pictoPartyApi";

export const store = configureStore({
  reducer: combineReducers({
    //todo: have a pictoparty root state, and contain below ones in them.
    usersList: userListReducer,
    gameDataSlice,
    [pictoPartyApi.reducerPath]: pictoPartyApi.reducer,
  }),

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Redux persist complains : A non-serializable value was detected in an action, in the path: `register`
      // So we remove below middleware (which is included by default by Redux toolkit)
      // https://github.com/rt2zz/redux-persist/issues/988
      serializableCheck: false,
    }).concat(pictoPartyApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Selector which changes values only on deep equality fail (like array or object).
type Selector<T> = (state: RootState) => T;
export const useDeepEqualSelector = <T>(selector: Selector<T>) => {
  return useAppSelector(selector, isEqual);
}

// Re-exporting from slices.
export * from './slices/gameDataSlice';
export * from './slices/userListSlice';

export const resetPictoPartyData = () => {
  store.dispatch(resetGameDataSlice());
  store.dispatch(resetUserSlice());
};