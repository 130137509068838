import { Tooltip } from "flowbite-react";
import React from "react";
import { MdInfoOutline } from "react-icons/md";

interface SliderInputProps {
  label: string;
  paragraphText: string;
  name: string;
  value: number;
  onChange: (name: string, value: number) => void;
  minValue: number;
  maxValue: number;
  readOnly?: boolean;
  labelUnit?: string;
}

const SliderInput: React.FC<SliderInputProps> = ({
  label,
  paragraphText,
  name,
  value,
  onChange,
  minValue,
  maxValue,
  readOnly=false,
  labelUnit='',
}) => {

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(readOnly) return;
    let inputValue = parseInt(event.target.value, 10);

    // Check if the input value is within the min and max range
    if (inputValue < minValue) {
      inputValue = minValue;
    } else if (inputValue > maxValue) {
      inputValue = maxValue;
    }

    // Update the value
    onChange(name, inputValue);
  };

  return (
    <div className="relative my-[12px]"> 
    <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-950"
      >
        <div className="flex justify-between text-xs">
            <div className="flex place-items-center gap-1"><div>{label}</div> <div><Tooltip content={paragraphText}><MdInfoOutline/></Tooltip></div></div>
            <div>{value} {labelUnit}</div>
        </div>
      </label>
    <input 
        id={name} 
        type="range" 
        value={value} 
        min={minValue} 
        max={maxValue} 
        onChange={handleInputChange} 
        readOnly={readOnly} 
        disabled={readOnly} 
        className="w-full h-2 bg-blue-900 rounded-lg appearance-none cursor-pointer" 
        required />
    <span className="text-xs text-gray-700 absolute start-0 -bottom-6">{minValue} {labelUnit}</span>
    <span className="text-xs text-gray-700 absolute end-0 -bottom-6">{maxValue} {labelUnit}</span>
</div>
    
  );
};

export default SliderInput;

{/* 

<div className="flex flex-wrap flex-row gap-1 place-items-center justify-between">
        
      <label
        htmlFor={name}
        className="block text-sm font-medium text-white w-[6rem]"
      >
        <div className="flex gap-1 place-items-center text-xs"><>{label}</> <Tooltip content={paragraphText} placement="top"><MdInfoOutline/></Tooltip></div>
      </label>
      <div className="relative flex items-center w-[9rem] place-items-center">
        <input
          type="number"
          id={name}
          value={value}
          onChange={handleInputChange}
          className={`${readOnly?"bg-gray-300":"bg-gray-50"} border-x-0 border-gray-300 h-11 text-center text-gray-900 text-xs px-2 focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${readOnly?"cursor-not-allowed":"cursor-pointer"}`}
          placeholder="999"
          readOnly={readOnly}
          disabled={readOnly}
          min={minValue}
          max={maxValue}
          required
        />
      </div>
    </div>

*/}