import { useEffect, useState } from "react";
import { useAppSelector } from "../store/store";
import { getTimer, getDuration } from "../store/store";

const useTimer = () => {
  const [seconds, setSeconds] = useState(0);
  const timer = useAppSelector(getTimer);
  const duration = useAppSelector(getDuration);

  useEffect(() => {
    if (!timer) {
      console.error("(timer) not set yet. Skipping Timer useEffect.");
      return;
    }
    setSeconds(timer);

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds >= 1) {
          return prevSeconds - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  return {seconds, duration:(duration||1)};
};

export default useTimer;
