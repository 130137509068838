import { useState } from "react";
import { nanoid } from "nanoid";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import { ClientPlayerData, CreateRoomRequestBody, Scores } from "../../../../common/types";
import Point from "../../../../domain/entities/Point";
import { getAllUsers, getDrawerId, getLastTurn, getUserAvatarId, setUserAvatarId, store } from "../store/store";
import { getSocketId, getUserId, getUserName } from "../store/store";
import { GameStatus, PaintType, PlayerStatus, RoomStatus, TurnStatus } from "../../../../common/Constants";
import { getGameData } from "../store/store";
import { sampleUserNames } from "../mockData/userMockData";


export function reduceFloatNumberToPrecision(
  floatNumber: number,
  precisionNumber: number = 0
): number {
  return parseFloat(floatNumber.toFixed(precisionNumber));
}

export function relaxPointPrecision(point: Point): Point {
  if (!point) return point;
  return {
    x: reduceFloatNumberToPrecision(point.x),
    y: reduceFloatNumberToPrecision(point.y),
  };
}

export const drawLineSegment = (
  ctx: CanvasRenderingContext2D,
  initial: Point,
  final: Point,
  color: string,
  width: number,
  paintType: PaintType,
) => {
  // Actually Draw Line Segment and Tip:
  initial = initial ?? final;

  // Line segment:
  ctx.beginPath();
  ctx.globalCompositeOperation = paintType === PaintType.ERASER ? 'destination-out' : 'source-over';
  ctx.lineWidth = width;
  ctx.strokeStyle = color;
  ctx.moveTo(initial.x, initial.y);
  ctx.lineTo(final.x, final.y);
  ctx.stroke();

  // Line tip:
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.arc(initial.x, initial.y, width / 2, 0, 2 * Math.PI);
  ctx.fill();

  ctx.globalCompositeOperation = 'source-over'; // Reset if Eraser used.
};

export const getPlayerRankings = (scores: Scores | undefined): Scores => {
  if (!scores) return {};
  // Convert the map to an array of objects for easier sorting
  const sortedScores = Object.entries(scores).sort((a, b) => b[1] - a[1]); // Sort by score in descending order

  const rankings: Scores = {};
  let currentRank = 1;

  // Assign ranks to each player based on their position in the sorted array
  for (let i = 0; i < sortedScores.length; i++) {
    const [playerId, score] = sortedScores[i];
    
    // If it's not the first player and the current score is equal to the previous player's score,
    // assign the same rank as the previous player
    if (i > 0 && score === sortedScores[i - 1][1]) {
      rankings[playerId] = currentRank;
    } else {
      currentRank = i + 1;
      rankings[playerId] = currentRank;
    }
  }

  return rankings;
};

export const createRandomRoomPayload = (): CreateRoomRequestBody => {
  return {
    userId: getUserId(store.getState()) as string, // This should be non-null.
    userName: getUserName(store.getState()) || nanoid(4),
    socketId: getSocketId(store.getState()) as string, // This should be non-null.
    avatarId: getUserAvatarId(store.getState()) as number, // Wont be null
  };
};

export const useGetFromQueryParam = (queryParamName: string) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(queryParamName) || "";
};

// Keep randomly choosing unseen avatars so far.
// When seen all, then start the cycle again 
// (shows ? mark(0.svg) when all seen), and then starts cycle again.
// Never ending selection menu.
export const useAvatarSelection = (
  maxAvatarIcons: number,
  initialAvatarId: number
) => {
  const [avatarId, setAvatarId] = useState<number>(initialAvatarId);
  const [avatarIdsSeen, setAvatarIdsSeen] = useState<number[]>([initialAvatarId]);

  const handleAvatarIdChange = (newAvatarId: number): void => {
    store.dispatch(setUserAvatarId(newAvatarId));
    setAvatarId(newAvatarId);
  };

  const handleChangeAvatar = () => {
    if (avatarIdsSeen.length === maxAvatarIcons) {
      handleAvatarIdChange(initialAvatarId);
      setAvatarIdsSeen([initialAvatarId]);
      return;
    }

    let newAvatarId;
    do {
      newAvatarId = Math.floor(Math.random() * maxAvatarIcons) + 1;
    } while (avatarIdsSeen.includes(newAvatarId));

    handleAvatarIdChange(newAvatarId);
    setAvatarIdsSeen([...avatarIdsSeen, newAvatarId]);
  };

  return { avatarId, handleChangeAvatar };
};

export const isRoomNotRunning = (status: RoomStatus): boolean => {
  return (
    status === RoomStatus.CREATED ||
    status === RoomStatus.FINISHED ||
    status === RoomStatus.IDLE
  );
};

export const isGameNotRunning = (status: GameStatus): boolean => {
  return (
    status === GameStatus.CREATED ||
    status === GameStatus.FINISHED ||
    status === GameStatus.IDLE
  );
};

export const isUserTheDrawerForThisTurn = (): boolean => {
  const userId = getUserId(store.getState());
  return isIdTheDrawerForThisTurn(userId);
};

export const isIdTheDrawerForThisTurn = (userId: string | null): boolean => {
  const drawerId = getDrawerId(store.getState());
  return userId === drawerId;
};

export const hasPlayerGuessedDrawing = (userId: string): boolean => {
  const turnData = getGameData(store.getState())?.turnData
  const drawerId = turnData?.drawerId;
  if (userId === drawerId) return false;

  const scores = turnData?.scores[userId];
  return (scores && scores?.length > 0) ? true : false
};

export const getFromLocalStorageByKey = (key: string) => {
  return localStorage.getItem(key);
}

export const setLocalStorageByKey = (key: string, val: string) => {
  localStorage.setItem(key, val);
}

export const isEmptyString = (value: string | null) => {
  return (value == null || (typeof value === "string" && value.trim().length === 0));
}

export const getARandomNonRepeatingUserName = (): string => {
  return sampleUserNames[Math.floor(Math.random() * sampleUserNames.length)];
}

export const movePlayerToBeginning = (array: ClientPlayerData[], playerId: string) => {
  array = _.cloneDeep(array);
  const index = array.findIndex((item) => item.uid === playerId);
  if (index === -1) {// Not found
    return array;
  }

  const item = array.splice(index, 1)[0];
  array.unshift(item);
  return array;
}

export function formatEpochSecsToString(epochSecs: number): string {
  let currentTime = new Date().getTime();
  let messageTime = new Date(epochSecs * 1000).getTime();

  let timeDifference = currentTime - messageTime;
  let seconds = Math.floor(timeDifference / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  if (seconds < 1) {
    return "now";
  } else if (minutes < 1) {
    return seconds + " sec ago";
  } else if (minutes < 5) {
    return Math.floor(minutes) + " min ago";
  } else if (hours < 24) {
    return new Date(messageTime).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else if (days < 365) {
    const messageTimeObj = new Date(messageTime);
    let renderDay = messageTimeObj.getDate();
    let renderMonth = messageTimeObj.toLocaleString("default", {
      month: "short",
    });
    let renderTime = messageTimeObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${renderDay} ${renderMonth} ${renderTime}`;
  } else {
    const messageTimeObj = new Date(messageTime);
    let renderYear = messageTimeObj.getFullYear();
    let renderDay = messageTimeObj.getDate();
    let renderMonth = messageTimeObj.toLocaleString("default", {
      month: "short",
    });
    let renderTime = messageTimeObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${renderDay} ${renderMonth} ${renderYear} ${renderTime}`;
  }
}

export const getUserFromUserId = (userId: string): ClientPlayerData | null => {
  const usersData = getAllUsers(store.getState());
  const foundUser = usersData.find(u => u.uid === userId);
  if (!foundUser) {
    return null;
  }
  return foundUser;
}

interface RGB {
  r: number;
  g: number;
  b: number;
}

interface HSL {
  h: number;
  s: number;
  l: number;
}

export function hexToRgb(hex: string): RGB {
  // Remove '#' if present
  hex = hex.replace(/^#/, '');

  // Parse the hex values
  const bigint = parseInt(hex, 16);

  // Extract the RGB components
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Return the RGB object
  return { r, g, b };
}

export function hexToRgbA(hex: string, alpha: number): string {
  // Validate alpha value
  if (alpha < 0 || alpha > 1) {
    throw new Error("Alpha value must be between 0 and 1.");
  }

  // Parse hex color components
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // Construct and return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function darkenHexColor(hex: string, factor: number): string {
  /*
    Usage of this function:
    const originalHexColor = "#FF0000"; // Red color
    const darkerHexColor = darkenHexColor(originalHexColor, 0.2); // Make it 20% darker
    console.log(darkerHexColor); // Output: #CC0000 (darker shade of red)
  */

  // Parse hex color components
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Darken each component by the factor
  r = Math.max(0, Math.round(r * (1 - factor)));
  g = Math.max(0, Math.round(g * (1 - factor)));
  b = Math.max(0, Math.round(b * (1 - factor)));

  // Convert the components back to hexadecimal and construct the new color
  const newHex = `#${(r * 0x10000 + g * 0x100 + b).toString(16).padStart(6, '0')}`;
  return newHex;
}

export function hexToHsl(hex: string): HSL {
  // Convert hex to RGB
  const { r, g, b } = hexToRgb(hex);

  // Convert RGB to HSL
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h: number = 0,
    s: number,
    l: number = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h, s, l };
}

export const allowedToDraw = (): boolean => {
  const turnData = getLastTurn(store.getState());
  return isUserTheDrawerForThisTurn() && turnData?.status === TurnStatus.STARTED;
}

export const getDisconnectedUsers = (playerList: ClientPlayerData[]): ClientPlayerData[] => {
  return playerList.filter(p => p.status !== PlayerStatus.CONNECTED);
}

export const maskAndAddWordLengthAndCommaSeparator = (inputString: string) : {maskedWords:string[], wordLengths: number[]} => {
 // Split the input string into words using any amount of whitespace as the separator.
 let words = inputString.trim().split(/\s+/);

 // Initialize the result array to store the transformed words.
 let result = [];

 // Iterate through each word in the array.
 // And also find their length:
 const wordLengths = [];
 for (let word of words) {
   // Create the masked word by replacing each character with an underscore.
   let maskedWord = word.trim(); // masking already done in backend.
   wordLengths.push(maskedWord.length);
   
   result.push(`${maskedWord.split('').join(' ')}`);
   
 }

  return { 
    maskedWords: result,
    wordLengths: wordLengths
  };
  
}