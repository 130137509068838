import { useEffect, useState } from 'react';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { isUserTheDrawerForThisTurn } from './utils';

export function useViewportHeight() {
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  const isKeybdIOpen = useDetectKeyboardOpen();
  const isDrawer =  isUserTheDrawerForThisTurn();

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight * 0.01);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isKeybdIOpen, isDrawer]);

  return vh;
}